import { GET_USER_INFO_SUCCESS } from "../ref";

// Tạo model
const firebaseModel = {
  user: null,
};
// Phương thức thay đổi dữ liệu của model
export default (state = firebaseModel, action) => {
  switch (action.type) {
    case GET_USER_INFO_SUCCESS:
      return {
        ...state,
        user: action.payload,
      };
    default:
      return state;
  }
};
