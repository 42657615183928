export const makeOrderId = (length) => {
  let result = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  const charactersKey = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  result += charactersKey.charAt(Math.floor(Math.random() * charactersKey.length));
  for (let i = 0; i < length - 1; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};
