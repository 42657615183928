// Tạo model cho Auth
const firebaseModel = {
  loginError: "",
  loading: false,
  error: "",
  user: null,
  products: null,
  templates: null,
  orderDetail: null,
  feedback: null,
};
// Phương thức thay đổi dữ liệu của model
export default (state = firebaseModel, action) => {
  switch (action.type) {
    case "firebase_loading":
      return {
        ...state,
        loading: true,
      };
    case "firebase_login_success":
      return {
        ...state,
        loginError: "",
        loading: false,
        user: action.payload,
      };
    case "firebase_login_fail":
      return {
        ...state,
        loginError: action.payload,
        loading: false,
      };
    case "firebase_logout_success":
      return {
        ...state,
        loginError: "",
        loading: false,
        user: null,
      };
    case "snapshot_products_list_success":
      return {
        ...state,
        products: action.payload,
      };
    case "snapshot_templates_list_success":
      return {
        ...state,
        templates: action.payload,
      };
    case "firestore_order_list_success":
      return {
        ...state,
        orders: action.payload,
      };

    case "set_detail_success":
      return {
        ...state,
        orderDetail: action.payload,
      };
    case "set_feedback_detail_success":
      return {
        ...state,
        feedback: action.payload,
      };
    case "firestore_report_supplied_list_success":
      return {
        ...state,
        reportSupplieds: action.payload,
      };
    case "firestore_report_return_list_success":
      return {
        ...state,
        reportReturns: action.payload,
      };
    default:
      return state;
  }
};
