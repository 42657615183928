import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";
import {
  Send,
  BallotOutlined,
  LocalLaundryServiceOutlined,
  LocalShippingOutlined,
  AssignmentTurnedInOutlined,
  Block,
} from "@material-ui/icons";
import moment from "moment";
import {
  SENT,
  PROCESSING,
  IN_TRANSIT,
  DELIVERY_SCHEDULED,
  COMPLETED,
  CANCELLED,
} from "../../../config/stringConfig";

class OrderDetailTop extends Component {
  // -------------------------- STATE --------------------------
  constructor() {
    super();
    this.state = {};
  }

  // -------------------------- FUNCTIONS --------------------------

  // -------------------------- FIREBASE FUNCTIONS --------------------------

  // -------------------------- RENDER --------------------------

  renderOrderID = () => {
    const { detail } = this.props;
    const orderId = detail?.orderId;
    const createAt = detail?.createAt;
    const scheduledFor = detail?.scheduledFor;
    const orderDate = createAt?.seconds && moment(createAt.toDate()).format("DD MMM YYYY hh:mmA");
    const scheduledDate =
      scheduledFor?.seconds && moment(scheduledFor.toDate()).format("DD MMM YYYY");
    const reference = detail?.reference;
    return (
      <Grid container spacing={0} direction="row" justify="space-between" alignItems="center">
        <Grid item xs={12}>
          Order Date: {orderDate}
        </Grid>
        <Grid item xs={12}>
          Scheduled Date: {scheduledDate}
        </Grid>
        <Grid item xs={12}>
          Order ID: {orderId}
        </Grid>
        <Grid item xs={12}>
          Ref #: {reference}
        </Grid>
      </Grid>
    );
  };

  renderStatus = () => {
    const { detail } = this.props;
    const status = detail?.status;
    switch (status) {
      case SENT:
        return <Send style={{ color: "red", float: "right" }} />;
      case PROCESSING:
        return <LocalLaundryServiceOutlined style={{ color: "#1976d2", float: "right" }} />;
      case DELIVERY_SCHEDULED:
        return <LocalShippingOutlined style={{ color: "orangered", float: "right" }} />;
      case IN_TRANSIT:
        return <LocalShippingOutlined style={{ color: "green", float: "right" }} />;
      case COMPLETED:
        return <AssignmentTurnedInOutlined style={{ color: "green", float: "right" }} />;
      case CANCELLED:
        return <Block style={{ color: "red", float: "right" }} />;
      default:
        return <BallotOutlined style={{ color: "#333", float: "right" }} />;
    }
  };

  // -------------------------- MAIN --------------------------

  render() {
    return (
      <Grid container spacing={0} direction="row" justify="space-between" alignItems="center">
        <Grid item xs={9}>
          {this.renderOrderID()}
        </Grid>
        <Grid item xs={3}>
          {this.renderStatus()}
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProp = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProp, mapDispatchToProps)(OrderDetailTop);
