import React, { Component } from "react";
import { connect } from "react-redux";
import {
  CssBaseline,
  Container,
  Grid,
  ButtonGroup,
  Button,
  IconButton,
  TextField,
  InputAdornment,
  CircularProgress,
} from "@material-ui/core";
import {
  ArrowBackIos,
  ThumbUpAltOutlined,
  ThumbDownAltOutlined,
  PhotoCamera,
  Close,
  Send,
} from "@material-ui/icons";
import moment from "moment";
import Navbar from "../00_Nav/nav_bar";
import { styles } from "./styles";
import EmojiRating from "../../component/rating";
import FeedBackDialogSuccess from "./dialogs/order_feedback_success";
import FeedBackDialogError from "./dialogs/order_feedback_error";
import OrderFeedbackdialogImage from "./dialogs/order_feedback_image_add";
import { firestoreAddFeedback } from "../../store/actions/firebase.action";
import { firestoreGetOrderDetail } from "../../store/actions/order.action";
import { URL } from "../../config/config";

class OrderDetailFeedBack extends Component {
  // -------------------------- STATE --------------------------
  constructor() {
    super();
    this.state = {
      reaction: null,
      noDamage: null,
      noWinkles: null,
      noStained: null,
      images: [],
      max: 5,
      comment: "",
      dialogSuccess: false,
      dialogError: false,
      dialogImage: false,
      inputLength: {
        note: 300,
      },
    };
  }

  componentDidMount = () => {
    this.getOrderDetail();
  };

  // -------------------------- FUNCTIONS --------------------------

  selectedReaction = (reaction) => {
    this.setState({
      reaction,
    });
  };

  handleChange = (e) => {
    const { inputLength } = this.state;
    const id = [e.target.id];
    const value = e.target.value;
    const condition = inputLength && inputLength[id];
    if (!condition || value.length < condition) {
      this.setState({
        [id]: value,
      });
    }
  };

  handleChangeDamage = (value) => {
    const { noDamage } = this.state;
    let newValue = value;
    if (noDamage === value) {
      newValue = null;
    }
    this.setState({
      noDamage: newValue,
    });
  };

  handleChangeWinkles = (value) => {
    const { noWinkles } = this.state;
    let newValue = value;
    if (noWinkles === value) {
      newValue = null;
    }
    this.setState({
      noWinkles: newValue,
    });
  };

  handleChangeStained = (value) => {
    const { noStained } = this.state;
    let newValue = value;
    if (noStained === value) {
      newValue = null;
    }
    this.setState({
      noStained: newValue,
    });
  };

  removeImage = (item) => {
    const { images } = this.state;
    const newImages = images.filter((img) => img !== item);
    this.setState({
      images: newImages,
    });
  };

  // Dialog success
  openDialogSuccess = () => {
    this.setState({
      dialogSuccess: true,
    });
  };

  closeDialogSuccess = () => {
    this.setState(
      {
        dialogSuccess: false,
      },
      () => {
        this.props.history.push(URL.orders);
      }
    );
  };

  // Dialog error
  openDialogError = () => {
    this.setState({
      dialogError: true,
    });
  };

  closeDialogError = () => {
    this.setState({
      dialogError: false,
    });
  };

  closeDialogErrorAndCall = () => {
    this.setState({
      dialogError: false,
    });
  };

  // Dialog Image

  openDialogImage = () => {
    this.setState({
      dialogImage: true,
    });
  };
  closeDialogImage = () => {
    this.setState({
      dialogImage: false,
    });
  };
  confirmDialogImage = (datas) => {
    this.setState(
      {
        dialogImage: false,
      },
      () => {
        const { images } = this.state;
        const newImages = [...images, ...datas];
        this.setState({
          images: newImages,
        });
      }
    );
  };
  // -------------------------- FIREBASE FUNCTIONS --------------------------

  getOrderDetail = () => {
    const orderId = this.props.match.params.id;
    this.props.firestoreGetOrderDetail({ orderId }, (order) => {
      this.setState({
        order,
      });
    });
  };

  sentFeedback = () => {
    const { reaction, noDamage, noWinkles, noStained, images, comment } = this.state;
    const { order } = this.state;
    const owner = order.owner;
    const branch = order.branch;
    const contactId = order.contactId;
    const contactName = order.contactName;
    const orderId = order.orderId;
    const recordId = order.id;
    const feedback = {
      owner,
      branch,
      orderId,
      recordId,
      contactId,
      contactName,
      reaction,
      noDamage,
      noWinkles,
      noStained,
      images,
      comment,
    };
    this.setState(
      {
        loading: true,
      },
      () => {
        this.props.firestoreAddFeedback({ feedback, order }, (response) => {
          if (response) {
            this.setState(
              {
                loading: false,
              },
              () => {
                this.openDialogSuccess();
              }
            );
          } else {
            this.setState(
              {
                loading: false,
              },
              () => {
                this.openDialogError();
              }
            );
          }
        });
      }
    );
  };

  // -------------------------- RENDER --------------------------

  renderLeftIcon = () => {
    return (
      <IconButton
        color="inherit"
        aria-label="back drawer"
        edge="start"
        onClick={() => this.props.history.goBack()}
      >
        <ArrowBackIos style={{ color: "#fff" }} />
      </IconButton>
    );
  };

  renderOrderId = () => {
    const { order } = this.state;
    const orderId = order && order.orderId;
    const createAt = order && order.createAt;
    const orderDate = createAt && moment(createAt.toDate()).format("DD MMM YYYY hh:mmA");
    const reference = order && order.reference;
    return (
      <Grid container spacing={1} direction="row" justify="space-between" alignItems="center">
        <Grid item xs={12}>
          Order Date: {orderDate}
        </Grid>
        <Grid item xs={12}>
          Order ID: {orderId}
        </Grid>
        <Grid item xs={12}>
          Ref #: {reference}
        </Grid>
      </Grid>
    );
  };

  renderRating = () => {
    return (
      <Grid container spacing={1} direction="row" justify="space-between" alignItems="center">
        <Grid item xs={12}>
          <h3>Overall rating</h3>
        </Grid>
        <Grid item xs={12} style={{ marginVertical: 20 }}>
          <EmojiRating width={360} space={15} onSelected={this.selectedReaction} />
        </Grid>
      </Grid>
    );
  };

  renderDetail = () => {
    const { reaction } = this.state;
    return (
      <Grid
        container
        spacing={1}
        direction="row"
        justify="space-between"
        alignItems="center"
        style={{ opacity: reaction ? 1 : 0.3 }}
      >
        <Grid item xs={12}>
          <h3>Tell us a bit more details</h3>
        </Grid>
        <Grid item xs={12}>
          {this.renderDetailDamage()}
        </Grid>
        <Grid item xs={12}>
          {this.renderDetailWinkles()}
        </Grid>
        <Grid item xs={12}>
          {this.renderDetailStained()}
        </Grid>
      </Grid>
    );
  };

  renderDetailDamage = () => {
    const { noDamage, reaction } = this.state;
    return (
      <Grid container spacing={1} direction="row" justify="flex-start" alignItems="center">
        <Grid item xs={2}>
          No damage
        </Grid>
        <Grid item>
          <ButtonGroup aria-label="outlined primary button group" style={{ marginTop: 5 }}>
            <Button
              className={`vote-button up ${noDamage === true ? "active" : ""}`}
              onClick={() => this.handleChangeDamage(true)}
              disabled={!reaction}
            >
              <ThumbUpAltOutlined />
            </Button>
            <Button
              className={`vote-button down ${noDamage === false ? "active" : ""}`}
              onClick={() => this.handleChangeDamage(false)}
              disabled={!reaction}
            >
              <ThumbDownAltOutlined />
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
    );
  };

  renderDetailWinkles = () => {
    const { noWinkles, reaction } = this.state;
    return (
      <Grid container spacing={1} direction="row" justify="flex-start" alignItems="center">
        <Grid item xs={2}>
          No winkles
        </Grid>
        <Grid item>
          <ButtonGroup aria-label="outlined primary button group" style={{ marginTop: 5 }}>
            <Button
              className={`vote-button up ${noWinkles === true ? "active" : ""}`}
              onClick={() => this.handleChangeWinkles(true)}
              disabled={!reaction}
            >
              <ThumbUpAltOutlined />
            </Button>
            <Button
              className={`vote-button down ${noWinkles === false ? "active" : ""}`}
              onClick={() => this.handleChangeWinkles(false)}
              disabled={!reaction}
            >
              <ThumbDownAltOutlined />
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
    );
  };

  renderDetailStained = () => {
    const { noStained, reaction } = this.state;
    return (
      <Grid container spacing={1} direction="row" justify="flex-start" alignItems="center">
        <Grid item xs={2}>
          No stained
        </Grid>
        <Grid item>
          <ButtonGroup aria-label="outlined primary button group" style={{ marginTop: 5 }}>
            <Button
              className={`vote-button up ${noStained === true ? "active" : ""}`}
              onClick={() => this.handleChangeStained(true)}
              disabled={!reaction}
            >
              <ThumbUpAltOutlined />
            </Button>
            <Button
              className={`vote-button down ${noStained === false ? "active" : ""}`}
              onClick={() => this.handleChangeStained(false)}
              disabled={!reaction}
            >
              <ThumbDownAltOutlined />
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
    );
  };

  renderImageData = () => {
    const { images, max, reaction } = this.state;
    const length = images ? images.length : 0;
    const lengthString = `${length}/${max}`;
    return (
      <Grid
        container
        spacing={1}
        direction="row"
        justify="space-between"
        alignItems="center"
        style={{ opacity: reaction ? 1 : 0.3 }}
      >
        <Grid item xs={12}>
          <h3>Pictures ({lengthString})</h3>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1} direction="row" justify="flex-start" alignItems="center">
            {images && images.map((item, index) => this.renderImageItem({ item, index }))}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  renderImageItem = ({ item, index }) => {
    const uri = `data:image/jpeg;base64,${item}`;
    return (
      <Grid item xs={2} key={index} style={styles.imagesContainer}>
        <img src={uri} style={styles.image} alt="screenshot" />
        <IconButton
          aria-label="delete"
          className="remove-screenshot"
          onClick={() => this.removeImage(item)}
        >
          <Close style={{ color: "red", fontSize: 15 }} />
        </IconButton>
      </Grid>
    );
  };

  renderImagePicker = () => {
    const { reaction, images, max } = this.state;
    const length = images ? images.length : 0;
    const disabled = length === max || !reaction;
    return (
      <Button
        variant="contained"
        startIcon={<PhotoCamera />}
        disabled={disabled}
        color="primary"
        onClick={() => this.openDialogImage()}
      >
        Upload Images
      </Button>
    );
  };

  renderImageUploadDialog = () => {
    const { images, max, dialogImage } = this.state;
    const length = images ? images.length : 0;
    const left = max - length;
    return (
      <OrderFeedbackdialogImage
        dialogOpen={dialogImage}
        handleClose={this.closeDialogImage}
        handleConfirm={this.confirmDialogImage}
        filesLimit={left}
      />
    );
  };

  renderComment = () => {
    const { comment, reaction } = this.state;
    const length = 300 - comment.length;
    return (
      <Grid
        container
        spacing={1}
        direction="row"
        justify="flex-start"
        alignItems="center"
        style={{ marginTop: 20, opacity: reaction ? 1 : 0.5 }}
      >
        <Grid item xs={12} className="count-string">
          <TextField
            variant="outlined"
            margin="dense"
            fullWidth
            id="comment"
            label="Comment"
            name="comment"
            type="text"
            value={comment}
            disabled={!reaction}
            onChange={this.handleChange}
            InputProps={{
              endAdornment: <InputAdornment position="end">{length}</InputAdornment>,
            }}
            multiline
            rows={2}
          />
        </Grid>
      </Grid>
    );
  };

  renderSubmitButton = () => {
    const { loading, reaction, order } = this.state;
    if (loading) {
      return (
        <Button
          variant="contained"
          fullWidth
          style={styles.button}
          disabled={!order}
          startIcon={<CircularProgress size={15} />}
        >
          Submit
        </Button>
      );
    }
    return (
      <Button
        variant="contained"
        fullWidth
        style={!reaction ? styles.buttonDisable : styles.button}
        onClick={() => this.sentFeedback()}
        startIcon={<Send size={15} />}
        disabled={!reaction || !order}
      >
        Submit
      </Button>
    );
  };

  // -------------------------- MAIN --------------------------

  render() {
    const { dialogSuccess, dialogError } = this.state;
    const { user } = this.props;
    const contactName = user?.contactName;

    console.log(this.props);
    return (
      <div className="root-container">
        <CssBaseline />
        <Container maxWidth="sm">
          <Navbar title="Leave Feedback" subtitle={contactName} left={this.renderLeftIcon()} />
          {this.renderOrderId()}
          {this.renderRating()}
          {this.renderDetail()}
          {this.renderImageData()}
          {this.renderImagePicker()}
          {this.renderImageUploadDialog()}
          {this.renderComment()}
          {this.renderSubmitButton()}
          <FeedBackDialogSuccess dialogOpen={dialogSuccess} handleClose={this.closeDialogSuccess} />
          <FeedBackDialogError
            dialogOpen={dialogError}
            handleClose={this.closeDialogError}
            handleCloseAndCall={this.closeDialogErrorAndCall}
          />
        </Container>
      </div>
    );
  }
}

const mapStateToProp = (state) => ({
  user: state.userReducer.user,
});

const mapDispatchToProps = {
  firestoreAddFeedback,
  firestoreGetOrderDetail,
};

export default connect(mapStateToProp, mapDispatchToProps)(OrderDetailFeedBack);
