import React, { Component } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  InputAdornment,
  CircularProgress,
} from "@material-ui/core";
import { connect } from "react-redux";
import { firestoreCancelOrder } from "../../../store/actions/firebase.action";

class OrderDetailDialogCancel extends Component {
  // -------------------------- STATE --------------------------
  constructor() {
    super();
    this.state = {
      loading: false,
      cancelReason: "",
      inputLength: {
        cancelReason: 100,
      },
    };
  }
  // -------------------------- FUNCTIONS --------------------------

  handleChange = (e) => {
    const { inputLength } = this.state;
    const id = [e.target.id];
    const value = e.target.value;
    const condition = inputLength && inputLength[id];
    if (!condition || value.length < condition) {
      this.setState({
        [id]: value,
      });
    }
  };

  // -------------------------- FIREBASE FUNCTIONS --------------------------

  cancelOrder = () => {
    const { detail, user } = this.props;
    const { cancelReason } = this.state;
    const contactName = user?.contactName;
    const order = {
      ...detail,
      cancelReason,
      cancelBy: contactName,
    };
    this.setState(
      {
        loading: true,
      },
      () => {
        this.props.firestoreCancelOrder({ order }, (response) => {
          this.setState(
            {
              loading: false,
            },
            () => {
              if (response) {
                this.props.handleConfirm();
              }
            }
          );
        });
      }
    );
  };

  // -------------------------- RENDER --------------------------

  renderDialogHeader = () => {
    return <DialogTitle id="scroll-dialog-title">Cancel Order!</DialogTitle>;
  };

  renderDialogBody = () => {
    const { detail } = this.props;
    const orderId = detail?.orderId;
    return (
      <DialogContent dividers>
        <p>Do you want to cancel order {orderId} ?</p>
        {this.renderCancelReason()}
      </DialogContent>
    );
  };

  renderCancelReason = () => {
    const { cancelReason } = this.state;
    const length = 100 - ((cancelReason && cancelReason.length) || 0);
    return (
      <Grid
        container
        spacing={1}
        direction="row"
        justify="flex-start"
        alignItems="center"
        style={{ marginTop: 10 }}
      >
        <Grid item xs={12} className="count-string">
          <TextField
            variant="outlined"
            margin="dense"
            fullWidth
            id="cancelReason"
            label="Cancel reason"
            name="cancelReason"
            type="text"
            value={cancelReason}
            onChange={this.handleChange}
            InputProps={{
              endAdornment: <InputAdornment position="end">{length}</InputAdornment>,
            }}
            multiline
          />
        </Grid>
      </Grid>
    );
  };

  renderDialogButton = () => {
    return (
      <DialogActions>
        <Button
          variant="outlined"
          className="add-btn"
          color="default"
          style={{ marginRight: 10 }}
          onClick={() => this.props.handleClose()}
        >
          No
        </Button>
        {this.renderButtonConfirm()}
      </DialogActions>
    );
  };

  renderButtonConfirm = () => {
    const { loading } = this.state;
    if (loading) {
      return (
        <Button
          variant="contained"
          className="add-btn"
          disabled
          style={{ marginRight: 5, backgroundColor: "red", color: "#fff" }}
          startIcon={<CircularProgress size={15} style={{ color: "#fff" }} />}
        >
          Yes
        </Button>
      );
    }
    return (
      <Button
        variant="outlined"
        className="add-btn"
        style={{ marginRight: 5, backgroundColor: "red", color: "#fff" }}
        onClick={() => this.cancelOrder()}
      >
        Yes
      </Button>
    );
  };

  // -------------------------- MAIN --------------------------
  render() {
    const { dialogOpen, handleClose } = this.props;
    return (
      <Dialog
        open={dialogOpen}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="sm"
        fullWidth
      >
        {this.renderDialogHeader()}
        {this.renderDialogBody()}
        {this.renderDialogButton()}
      </Dialog>
    );
  }
}

const mapStateToProp = (state) => ({
  user: state.userReducer.user,
});

const mapDispatchToProps = {
  firestoreCancelOrder,
};

export default connect(mapStateToProp, mapDispatchToProps)(OrderDetailDialogCancel);
