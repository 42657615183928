import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

// Erp Sanbox
export const BRAND_NAME = "Chatters Laundry Client"; // App Name
export const SANDBOX = false;
export const VERSION = "1.0.29";
const firebaseConfig = {
  apiKey: "AIzaSyA_RrHa3HPRafer1g93b0P8chyTyPBWTjE",
  authDomain: "chatters-laundry.firebaseapp.com",
  databaseURL: "https://chatters-laundry.firebaseio.com",
  projectId: "chatters-laundry",
  storageBucket: "chatters-laundry.appspot.com",
  messagingSenderId: "401415329518",
  appId: "1:401415329518:web:94fac9199019fb6a4445ff",
  measurementId: "G-RPFJNEMH3Y",
};

firebase.initializeApp(firebaseConfig);

export default firebase;

export const authApp = firebase.initializeApp(firebaseConfig, "authApp");

// ========================= App String ========================= //
export const MAP_API_KEY = "AIzaSyCu_IMhYlj7IuVoC2w_m9d2OR_BfYqMazw"; // Google map API
export const MAX_INPUT = 8; // Tối đa số lượt input in out
export const URL = {
  // url
  orders: "/",
  signin: "/",
  newOrder: "/new-order",
  orderDetailRoot: "/order-detail",
  orderDetail: "/order-detail/:id",
  newFeedbackRoot: "/new-feedback",
  newFeedback: "/new-feedback/:id",
  feedbackDetail: "/feedback-detail",
  suppliedVSReturn: "/supplied-vs-return",
};
