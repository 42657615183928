import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";

class OrderDetailTable extends Component {
  // -------------------------- STATE --------------------------
  constructor() {
    super();
    this.state = {
      header: [
        { id: "item", label: "Item", align: "left", sortable: false },
        { id: "quantity", label: "Quantity", align: "right", sortable: false },
      ],
      loading: false,
    };
  }

  // -------------------------- FUNCTIONS --------------------------

  // -------------------------- FIREBASE FUNCTIONS --------------------------

  // -------------------------- RENDER --------------------------

  renderTableHead = () => {
    const { header } = this.state;
    return (
      <TableHead>
        <TableRow>
          {header &&
            header.map((headCell) => (
              <TableCell key={headCell.id} align={headCell.align} padding="default">
                {headCell.label}
              </TableCell>
            ))}
        </TableRow>
      </TableHead>
    );
  };

  renderTableBody = () => {
    const { detail } = this.props;
    const products = detail?.products;
    const difference = detail?.difference;
    return (
      <TableBody>
        {products?.map((item, index) => this.renderTableRow({ item, index, difference }))}
      </TableBody>
    );
  };

  renderTableRow = ({ item, index, difference }) => {
    const description = item?.description;
    const inventoryItemCode = item?.inventoryItemCode;
    const quantity = item?.quantity;
    const fullName = `${inventoryItemCode} - ${description}`;
    return (
      <TableRow hover key={index}>
        <TableCell align="left">{fullName}</TableCell>
        <TableCell align="right">
          <div className="detail-quantity">
            {quantity}
            {this.renderDifference({ item, difference })}
          </div>
        </TableCell>
      </TableRow>
    );
  };

  renderDifference = ({ item, difference }) => {
    const diffObj = difference?.find((diff) => diff?.inventoryItemCode === item?.inventoryItemCode);
    const diffAmount = diffObj?.diff;
    if (diffAmount && diffAmount > 0) {
      return <div className="detail-quantity-diff green">{diffAmount}</div>;
    }
    if (diffAmount && diffAmount < 0) {
      return <div className="detail-quantity-diff red">{diffAmount}</div>;
    }
    return null;
  };

  // -------------------------- MAIN --------------------------

  render() {
    return (
      <Paper style={{ marginBottom: 20 }}>
        <TableContainer className="report-table">
          <Table stickyHeader aria-label="sticky table">
            {this.renderTableHead()}
            {this.renderTableBody()}
          </Table>
        </TableContainer>
      </Paper>
    );
  }
}

const mapStateToProp = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProp, mapDispatchToProps)(OrderDetailTable);
