import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";
import moment from "moment";

class OrderDetailTracking extends Component {
  // -------------------------- STATE --------------------------
  constructor() {
    super();
    this.state = {};
  }

  // -------------------------- FUNCTIONS --------------------------

  // -------------------------- FIREBASE FUNCTIONS --------------------------

  // -------------------------- RENDER --------------------------

  renderCreated = () => {
    const { detail } = this.props;
    const createAt = detail?.createAt;
    const createBy = detail?.createBy && `- ${detail?.createBy}`;
    const orderCreated =
      createAt?.seconds && moment(createAt.toDate()).format("DD MMM YYYY hh:mmA");
    return createAt ? (
      <Grid item xs={12}>
        Order created: {orderCreated} {createBy}
      </Grid>
    ) : null;
  };

  renderScheduled = () => {
    const { detail } = this.props;
    const scheduledFor = detail?.scheduledFor;
    const scheduleBy = detail?.scheduleBy && `- ${detail?.scheduleBy}`;
    const scheduledDate =
      scheduledFor?.seconds && moment(scheduledFor.toDate()).format("DD MMM YYYY");
    return scheduledFor ? (
      <Grid item xs={12}>
        Scheduled for: {scheduledDate} {scheduleBy}
      </Grid>
    ) : null;
  };

  renderConfirmed = () => {
    const { detail } = this.props;
    const confirmAt = detail?.confirmAt;
    const confirmBy = detail?.confirmBy && `- ${detail?.confirmBy}`;
    const orderConfirmed =
      confirmAt?.seconds && moment(confirmAt.toDate()).format("DD MMM YYYY hh:mmA");
    return confirmAt ? (
      <Grid item xs={12}>
        Order confirmed: {orderConfirmed} {confirmBy}
      </Grid>
    ) : null;
  };

  renderDeliveryScheduled = () => {
    const { detail } = this.props;
    const deliveryScheduleAt = detail?.deliveryScheduleAt;
    const deliveryScheduleBy = detail?.deliveryScheduleBy && `- ${detail?.deliveryScheduleBy}`;
    const deliveryScheduled =
      deliveryScheduleAt?.seconds && moment(deliveryScheduleAt.toDate()).format("DD MMM YYYY");
    return deliveryScheduleAt ? (
      <Grid item xs={12}>
        Delivery Scheduled: {deliveryScheduled} {deliveryScheduleBy}
      </Grid>
    ) : null;
  };

  renderInTransit = () => {
    const { detail } = this.props;
    const transitAt = detail?.transitAt;
    const transitBy = detail?.transitBy && `- ${detail?.transitBy}`;
    const inTransit = transitAt?.seconds && moment(transitAt.toDate()).format("DD MMM YYYY hh:mmA");
    return transitAt ? (
      <Grid item xs={12}>
        In Transit: {inTransit} {transitBy}
      </Grid>
    ) : null;
  };

  renderCancelled = () => {
    const { detail } = this.props;
    const cancelAt = detail?.cancelAt;
    const cancelBy = detail?.cancelBy && `- ${detail?.cancelBy}`;
    const cancelled = cancelAt?.seconds && moment(cancelAt.toDate()).format("DD MMM YYYY hh:mmA");
    return cancelAt ? (
      <Grid item xs={12}>
        Cancelled: {cancelled} {cancelBy}
      </Grid>
    ) : null;
  };

  renderCompleted = () => {
    const { detail } = this.props;
    const completeAt = detail?.completeAt;
    const completeBy = detail?.completeBy && `- ${detail?.completeBy}`;
    const completed =
      completeAt?.seconds && moment(completeAt.toDate()).format("DD MMM YYYY hh:mmA");
    return completeAt ? (
      <Grid item xs={12}>
        Completed: {completed} {completeBy}
      </Grid>
    ) : null;
  };

  // -------------------------- MAIN --------------------------

  render() {
    return (
      <Grid
        container
        spacing={1}
        direction="row"
        justify="flex-start"
        alignItems="center"
        style={{ marginTop: 10 }}
      >
        <Grid item xs={12}>
          <h3 style={{ color: "green" }}>Tracking details</h3>
        </Grid>
        {this.renderCreated()}
        {this.renderScheduled()}
        {this.renderConfirmed()}
        {this.renderDeliveryScheduled()}
        {this.renderInTransit()}
        {this.renderCancelled()}
        {this.renderCompleted()}
      </Grid>
    );
  }
}

const mapStateToProp = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProp, mapDispatchToProps)(OrderDetailTracking);
