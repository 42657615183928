import sumBy from 'lodash/sumBy';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';

export const suppliedReturnToData = ({ reportReturns, reportSupplieds, templates }) => {
    if (reportReturns && reportSupplieds) {
        let supplied = [];
        let returns = [];
        reportSupplieds.forEach(item => {
            const products = item.products;
            supplied = [...supplied, ...products];
        });
        reportReturns.forEach(item => {
            const products = item.products;
            returns = [...returns, ...products];
        });
        const groupSupplied = groupBy(supplied, (x => x.inventoryItemCode));
        const groupReturns = groupBy(returns, (x => x.inventoryItemCode));
        let result = [];
        Object.keys(groupSupplied).forEach(key => {
            const allSupplies = groupSupplied[key];
            const allReturns = groupReturns[key];
            const code = allSupplies && allSupplies[0].inventoryItemCode;
            const name = allSupplies && allSupplies[0].description;
            const itemName = code && name && `${code} - ${name}`;
            const suppliedQuantity = sumBy(allSupplies, (data) => {
                return parseFloat(data.quantity);
            });
            const returnQuantity = sumBy(allReturns, (data) => {
                return parseFloat(data.quantity);
            });
            const index = templates && templates.findIndex(item => item.inventoryItemCode === code);
            const percent = parseFloat((returnQuantity / suppliedQuantity) * 100);
            const item = {
                index,
                code,
                itemName,
                suppliedQuantity,
                returnQuantity,
                percent
            };
            result = [...result, item];
        });
        let sortAble = result.filter(r => r.index >= 0);
        sortAble = sortBy(sortAble, ['index'], ['asc']);
        const unSortAble = result.filter(r => r.index === -1);
        const final = [...sortAble, ...unSortAble];
        return final;
    }
};
