import React, { Component} from 'react';
import { 
    Button, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogTitle,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { SUPPORT_NUMBER, SUPPORT_NAME } from '../../../config/stringConfig';

class NewOrderDialogError extends Component {
    // -------------------------- STATE --------------------------
    constructor() {
        super();
        this.state = {
        };
    }
    // -------------------------- FUNCTIONS --------------------------

    // -------------------------- FIREBASE FUNCTIONS --------------------------

    // -------------------------- RENDER --------------------------

    renderDialogHeader = () => {
        return <DialogTitle id="scroll-dialog-title">Unsuccessfully</DialogTitle>
    }

    renderDialogBody = () => {
        return (
            <DialogContent dividers >
                <p>Oops! The order couldn't send through successfully.</p>
                <p>Please check Internet connection and try again</p>
                <p>Or call us on {SUPPORT_NUMBER} - {SUPPORT_NAME} to get further support.</p>
            </DialogContent>
        );
    }

    renderDialogButton = () => {
        return (
            <DialogActions>
                <Button 
                  type="submit"
                  variant="outlined"
                  className='add-btn'
                  color='green'
                  style={{ marginRight: 10 }}
                  onClick={() => this.props.handleCloseAndCall()}
                >
                  Request support
                </Button>
                <Button 
                  type="submit"
                  variant="outlined"
                  className='add-btn'
                  color="primary"
                  style={{ marginRight: 5 }}
                  onClick={() => this.props.handleClose()}
                >
                  Ok
                </Button>
            </DialogActions>
        );
    }
    
    // -------------------------- MAIN --------------------------
    render() {
        const { dialogOpen, handleClose } = this.props;
        return (
            <Dialog
                open={dialogOpen}
                onClose={handleClose}
                scroll='paper'
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                maxWidth='sm'
                fullWidth
            >
                { this.renderDialogHeader() }
                { this.renderDialogBody() }
                { this.renderDialogButton() }
            </Dialog>
        )
    }
}

// const mapStateToProp = (state) => ({
//     user: state.firebase.user,
// });

// const mapDispatchToProps = {
// };

export default connect(null, null)(NewOrderDialogError);  

