import React, { Component } from "react";
import { connect } from "react-redux";
import { CssBaseline, Container, IconButton } from "@material-ui/core";
import { ArrowBackIos, Block, TodayRounded } from "@material-ui/icons";
import Navbar from "../00_Nav/nav_bar";
import { SENT, PROCESSING, COMPLETED, CANCELLED } from "../../config/stringConfig";
import { URL } from "../../config/config";
import OrderDetailTop from "./component/order_detail_top";
import OrderDetailTable from "./component/order_detail_table";
import OrderDetailTracking from "./component/order_detail_tracking";
import OrderDetailNote from "./component/order_detail_note";
import OrderDetailFeedback from "./component/order_detail_feedback";
import OrderDetailPicture from "./component/order_detail_picture";
import OrderDetailDialogCancel from "./dialogs/order_detail_dialog_cancel";
import OrderDetailDialogScheduled from "./dialogs/order_detail_dialog_scheduled";
import { firestoreGetOrderDetail } from "../../store/actions/order.action";
import { firestoreGetUserInfo } from "../../store/actions/user.action";

class OrderDetailScreen extends Component {
  // -------------------------- STATE --------------------------
  constructor() {
    super();
    this.state = {
      loading: false,
      dialogCancel: false,
      dialogScheduled: false,
    };
  }

  componentDidMount = () => {
    this.getOrderDetail();
  };

  // -------------------------- FUNCTIONS --------------------------

  // Dialog Cancel
  openDialogCancel = () => {
    this.setState({
      dialogCancel: true,
    });
  };

  closeDialogCancel = () => {
    this.setState({
      dialogCancel: false,
    });
  };

  confirmDialogCancel = () => {
    this.setState(
      {
        dialogCancel: false,
      },
      () => {
        this.props.history.push(URL.orders);
      }
    );
  };

  // Dialog Cancel
  openDialogScheduled = () => {
    this.setState({
      dialogScheduled: true,
    });
  };

  closeDialogScheduled = () => {
    this.setState({
      dialogScheduled: false,
    });
  };

  confirmDialogScheduled = () => {
    this.setState(
      {
        dialogScheduled: false,
      },
      () => {
        this.props.history.push(URL.orders);
      }
    );
  };

  // -------------------------- FIREBASE FUNCTIONS --------------------------

  getOrderDetail = () => {
    const orderId = this.props.match.params.id;
    this.props.firestoreGetOrderDetail({ orderId }, (detail) => {
      this.setState({
        detail,
      });
    });
  };

  // -------------------------- RENDER --------------------------

  renderLeftIcon = () => {
    return (
      <IconButton
        color="inherit"
        aria-label="back drawer"
        edge="start"
        onClick={() => this.props.history.goBack()}
      >
        <ArrowBackIos style={{ color: "#fff" }} />
      </IconButton>
    );
  };

  renderRightIcon = () => {
    return (
      <div style={{ flexDirection: "row" }}>
        {this.renderRightIconCancel()}
        {this.renderRightIconScheduled()}
      </div>
    );
  };

  renderRightIconCancel = () => {
    const { detail } = this.state;
    const status = detail?.status;
    if (status === COMPLETED || status === CANCELLED) {
      return null;
    }
    return (
      <div className="right-button-with-title" onClick={() => this.openDialogCancel()}>
        <Block style={{ color: "#fff" }} />
        <div className="header-label">Cancel</div>
      </div>
    );
  };

  renderRightIconScheduled = () => {
    const { detail } = this.state;
    const status = detail?.status;
    if (status === SENT || status === PROCESSING) {
      return (
        <div className="right-button-with-title" onClick={() => this.openDialogScheduled()}>
          <TodayRounded style={{ color: "#fff" }} />
          <div className="header-label">Schedule</div>
        </div>
      );
    }
    return null;
  };

  // -------------------------- MAIN --------------------------

  render() {
    const { user } = this.props;
    const { detail } = this.state;
    const contactName = user?.contactName;
    const { dialogCancel, dialogScheduled } = this.state;
    return (
      <div className="root-container">
        <CssBaseline />
        <Container maxWidth="sm">
          <Navbar
            title="Order Detail"
            subtitle={contactName}
            left={this.renderLeftIcon()}
            right={this.renderRightIcon()}
          />
          <OrderDetailTop detail={detail} />
          <OrderDetailTable detail={detail} />
          <OrderDetailNote detail={detail} />
          <OrderDetailPicture detail={detail} />
          <OrderDetailTracking detail={detail} />
          <OrderDetailFeedback history={this.props.history} detail={detail} />
          <OrderDetailDialogCancel
            detail={detail}
            dialogOpen={dialogCancel}
            handleClose={this.closeDialogCancel}
            handleConfirm={this.confirmDialogCancel}
          />
          <OrderDetailDialogScheduled
            detail={detail}
            dialogOpen={dialogScheduled}
            handleClose={this.closeDialogScheduled}
            handleConfirm={this.confirmDialogScheduled}
          />
        </Container>
      </div>
    );
  }
}

const mapStateToProp = (state) => ({
  user: state.userReducer.user,
});

const mapDispatchToProps = {
  firestoreGetOrderDetail,
  firestoreGetUserInfo,
};

export default connect(mapStateToProp, mapDispatchToProps)(OrderDetailScreen);
