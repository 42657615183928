/* eslint-disable no-unused-vars */
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import unionBy from "lodash/unionBy";
import orderBy from "lodash/orderBy";
import {
  LOCAL_TEMPLATE,
  LOCAL_USER,
  SYSTEM_EMAIL,
  SYSTEM_PASSWORD,
  SENT,
  CANCELLED,
} from "../../config/stringConfig";
import { makeOrderId } from "../../util/functions";
import { GET_USER_INFO_SUCCESS } from "../ref";

// ========================== User ==========================

export const firebaseLogin =
  ({ email, password }, callback) =>
  (dispatch) => {
    firebaseLoading(dispatch);
    firebase
      .auth()
      .signInWithEmailAndPassword(SYSTEM_EMAIL, SYSTEM_PASSWORD)
      .then(() => {
        const contactRef = firebase.firestore().collection("contacts");
        contactRef
          .where("emailAddress", "==", email)
          .where("password", "==", password)
          .get()
          .then((snapshot) => {
            if (snapshot) {
              const data = snapshot.docs.map((doc) => doc.data());
              const user = data && data[0];
              if (user) {
                const userJson = JSON.stringify({
                  id: user.id,
                });
                localStorage.setItem(LOCAL_USER, userJson);
                getUserInfoSuccess(dispatch, user);
                if (callback) {
                  callback(user);
                }
              } else {
                firebase.auth().signOut();
                firebaseLoginFail(
                  dispatch,
                  "Wrong password or your account has not been created. Please contact the administrator."
                );
                if (callback) {
                  callback(false);
                }
              }
            }
          })
          .catch((error) => {
            console.log("faied", error);
            firebaseLoginFail(dispatch, error.message);
            if (callback) {
              callback(false);
            }
          });
      }) // Thành công
      .catch((error) => {
        firebaseLoginFail(dispatch, error.message);
        if (callback) {
          callback(false);
        }
      }); // Lỗi
  };

const firebaseLoading = (dispatch) => {
  dispatch({
    type: "firebase_loading",
  });
};

const getUserInfoSuccess = (dispatch, user) => {
  dispatch({
    type: GET_USER_INFO_SUCCESS,
    payload: user,
  });
};

// Đăng nhập thất bại
const firebaseLoginFail = (dispatch, error) => {
  dispatch({
    type: "firebase_login_fail",
    payload: error,
  });
};

export const firebaseLogout = () => (dispatch) => {
  firebase
    .auth()
    .signOut()
    .then(() => {
      localStorage.clear();
    });
  firebaseLogoutSuccess(dispatch);
};

// Đăng xuất thành công
const firebaseLogoutSuccess = (dispatch) => {
  dispatch({
    type: "firebase_logout_success",
  });
};

// -------------------------- Products --------------------------\

const subProduct = [];
export const firestoreSnapshotProducts =
  ({ user }, callback) =>
  (dispatch) => {
    const owner = user && user.owner;
    const branch = user && user.branch;
    const unSub = firebase
      .firestore()
      .collection("products")
      .where("owner", "==", owner)
      .where("branch", "==", branch)
      .onSnapshot((snapshot) => {
        if (snapshot) {
          const data = snapshot.docs.map((doc) => doc.data());
          snapshotProductsSuccess(dispatch, data);
        }
        if (callback) {
          callback();
        }
      });
    subProduct.push(unSub);
  };

const snapshotProductsSuccess = (dispatch, data) => {
  dispatch({
    type: "snapshot_products_list_success",
    payload: data,
  });
};

export const unSnapProduct = () => (dispatch) => {
  subProduct.forEach((subscriber) => {
    subscriber();
  });
  subProduct.length = 0;
};

// -------------------------- Templates --------------------------
const subTemplate = [];
export const firestoreSnapshotTemplate =
  ({ user }, callback) =>
  (dispatch) => {
    const owner = user && user.owner;
    const branch = user && user.branch;
    const contactId = user && user.id;
    const unSub = firebase
      .firestore()
      .collection("templates")
      .where("owner", "==", owner)
      .where("branch", "==", branch)
      .where("contactId", "==", contactId)
      .onSnapshot((snapshot) => {
        if (snapshot) {
          let data = snapshot.docs.map((doc) => doc.data());
          data = orderBy(data, ["createAt"], ["asc"]);
          snapshotTemplateSuccess(dispatch, data);
        }
        if (callback) {
          callback();
        }
      });
    subTemplate.push(unSub);
  };

const snapshotTemplateSuccess = (dispatch, data) => {
  dispatch({
    type: "snapshot_templates_list_success",
    payload: data,
  });
};

export const unSnapTemplate = () => (dispatch) => {
  subTemplate.forEach((subscriber) => {
    subscriber();
  });
  subTemplate.length = 0;
};

// -------------------------- Order --------------------------

export const firestoreAddOrder =
  ({ data }, callback) =>
  (dispatch) => {
    const ordersRef = firebase.firestore().collection("orders");
    const id = ordersRef.doc().id;
    const orderData = {
      ...data,
      id,
      createAt: firebase.firestore.FieldValue.serverTimestamp(),
      status: SENT,
    };
    ordersRef
      .doc(id)
      .set(orderData, { merge: true })
      .then(() => {
        if (callback) {
          callback(true);
        }
      })
      .catch(() => {
        if (callback) {
          callback(false);
        }
      });
  };

export const firestoreCancelOrder =
  ({ order }, callback) =>
  (dispatch) => {
    const ordersRef = firebase.firestore().collection("orders");
    const id = order.id;
    const orderData = {
      ...order,
      cancelAt: firebase.firestore.FieldValue.serverTimestamp(),
      status: CANCELLED,
    };
    ordersRef
      .doc(id)
      .update(orderData)
      .then(() => {
        if (callback) {
          callback(true);
        }
      })
      .catch(() => {
        if (callback) {
          callback(false);
        }
      });
  };

export const firestoreUpdateOrderSchedule =
  ({ order }, callback) =>
  (dispatch) => {
    const ordersRef = firebase.firestore().collection("orders");
    const id = order.id;
    ordersRef
      .doc(id)
      .update(order)
      .then(() => {
        if (callback) {
          callback(true);
        }
      })
      .catch(() => {
        if (callback) {
          callback(false);
        }
      });
  };

export const firestoreGetOrderListFromTo =
  ({ from, to, user }, callback) =>
  (dispatch) => {
    const ordersRef = firebase.firestore().collection("orders");
    const owner = user.owner;
    const branch = user.branch;
    const contactId = user && user.id;
    const start = from._d;
    const end = to._d;
    ordersRef
      .where("owner", "==", owner)
      .where("branch", "==", branch)
      .where("contactId", "==", contactId)
      .where("scheduledFor", ">=", start)
      .where("scheduledFor", "<=", end)
      .get()
      .then((snapshot) => {
        if (snapshot) {
          const data = snapshot.docs.map((doc) => doc.data());
          getOrderListSuccess(dispatch, data);
        }
        if (callback) {
          callback();
        }
      });
  };

const getOrderListSuccess = (dispatch, data) => {
  dispatch({
    type: "firestore_order_list_success",
    payload: data,
  });
};

export const firestoreGetNewOrderId = (callback) => (dispatch) => {
  const ordersRef = firebase.firestore().collection("orders");
  const orderId = makeOrderId(5);
  ordersRef
    .where("orderId", "==", orderId)
    .get()
    .then((snapshot) => {
      if (snapshot) {
        const data = snapshot.docs.map((doc) => doc.data());
        if (data.length === 0) {
          callback(orderId);
        } else {
          callback(false);
        }
      } else if (callback) {
        callback(false);
      }
    });
};

// -------------------------- Local Template --------------------------
export const setLocalTemplate =
  ({ orderList }) =>
  () => {
    const json = localStorage.getItem(LOCAL_TEMPLATE);
    if (json) {
      const localTemplate = JSON.parse(json);
      const newTemplate = unionBy(orderList, localTemplate, "inventoryItemCode");
      const templateJson = JSON.stringify(newTemplate);
      localStorage.setItem(LOCAL_TEMPLATE, templateJson);
    } else {
      const templateJson = JSON.stringify(orderList);
      localStorage.setItem(LOCAL_TEMPLATE, templateJson);
    }
  };

export const getLocalTemplate =
  ({ templates }, callback) =>
  () => {
    const json = localStorage.getItem(LOCAL_TEMPLATE);
    if (json) {
      const local = json && JSON.parse(json);
      const newTemplate =
        templates &&
        templates.map((item) => {
          const exists = local.find((l) => l.inventoryItemCode === item.inventoryItemCode);
          if (exists) {
            return {
              ...item,
              quantity: exists.quantity,
            };
          }
          return item;
        });
      if (callback) {
        callback(newTemplate);
      }
    } else if (callback) {
      callback(templates);
    }
  };

// -------------------------- Feed Back --------------------------

export const firestoreAddFeedback =
  ({ feedback, order }, callback) =>
  (dispatch) => {
    const feedbackRef = firebase.firestore().collection("feedbacks");
    const id = feedbackRef.doc().id;
    const data = {
      ...feedback,
      id,
      createAt: firebase.firestore.FieldValue.serverTimestamp(),
    };
    // Update feedback
    const feedPromise = feedbackRef.doc(id).set(data, { merge: true });
    // Update order
    const ordersRef = firebase.firestore().collection("orders");
    const orderPromise = ordersRef.doc(order.id).update({ feedback: id });
    const allPromise = [feedPromise, orderPromise];
    Promise.all(allPromise)
      .then(() => {
        if (callback) {
          callback(true);
        }
      })
      .catch(() => {
        if (callback) {
          callback(false);
        }
      });
  };

export const firestoreViewFeedback =
  ({ id }, callback) =>
  (dispatch) => {
    const feedbackRef = firebase.firestore().collection("feedbacks");
    // Update feedback
    feedbackRef
      .doc(id)
      .get()
      .then((snapshot) => {
        if (snapshot) {
          const data = snapshot.data();
          setFeedbackSuccess(dispatch, data);
          if (callback) {
            callback();
          }
        }
      });
  };

const setFeedbackSuccess = (dispatch, data) => {
  dispatch({
    type: "set_feedback_detail_success",
    payload: data,
  });
};

// -------------------------- Report Supply & Return --------------------------

export const firestoreGetSuppliedReturnFromTo =
  ({ from, to, contact }, callback) =>
  (dispatch) => {
    const owner = contact && contact.owner;
    const branch = contact && contact.branch;
    const contactId = contact && contact.id;
    const start = from._d;
    const end = to._d;
    // Lấy dữ liệu report của order, supplied tức là completed
    const ordersRef = firebase.firestore().collection("orders");
    const orderPromise = ordersRef
      .where("owner", "==", owner)
      .where("branch", "==", branch)
      .where("contactId", "==", contactId)
      .where("completeAt", ">=", start)
      .where("completeAt", "<=", end)
      .get()
      .then((snapshot) => {
        if (snapshot) {
          const data = snapshot.docs.map((doc) => doc.data());
          getReportOrderListSuccess(dispatch, data);
        }
      });
    // Lấy dữ liệu report của return
    const returnsRef = firebase.firestore().collection("returns");
    const returnsPromise = returnsRef
      .where("owner", "==", owner)
      .where("branch", "==", branch)
      .where("contactId", "==", contactId)
      .where("createAt", ">=", start)
      .where("createAt", "<=", end)
      .get()
      .then((snapshot) => {
        if (snapshot) {
          const data = snapshot.docs.map((doc) => doc.data());
          getReportReturnListSuccess(dispatch, data);
        }
      });
    const allPromise = [orderPromise, returnsPromise];
    // Xử lý
    Promise.all(allPromise).then(() => {
      if (callback) {
        callback();
      }
    });
  };

const getReportOrderListSuccess = (dispatch, data) => {
  dispatch({
    type: "firestore_report_supplied_list_success",
    payload: data,
  });
};

const getReportReturnListSuccess = (dispatch, data) => {
  dispatch({
    type: "firestore_report_return_list_success",
    payload: data,
  });
};
