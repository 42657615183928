import React, { Component } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import { connect } from "react-redux";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { firestoreUpdateOrderSchedule } from "../../../store/actions/firebase.action";

class OrderDetailDialogScheduled extends Component {
  // -------------------------- STATE --------------------------
  constructor() {
    super();
    this.state = {
      loading: false,
      scheduledFor: moment(),
    };
  }
  // -------------------------- FUNCTIONS --------------------------

  handleDateChange = (date, id) => {
    this.setState({
      [id]: date,
    });
  };

  componentDidMount = () => {
    const { detail } = this.props;
    const scheduledFor = detail?.scheduledFor;
    const dateTime = (scheduledFor && scheduledFor.toDate()) || moment();
    this.setState({
      scheduledFor: dateTime,
    });
  };

  // -------------------------- FIREBASE FUNCTIONS --------------------------

  updateOrder = () => {
    const { detail, user } = this.props;
    const { scheduledFor } = this.state;
    const contactName = user?.contactName;
    const order = {
      ...detail,
      scheduledFor: scheduledFor._d,
      scheduleBy: contactName,
    };
    this.setState(
      {
        loading: true,
      },
      () => {
        this.props.firestoreUpdateOrderSchedule({ order }, (response) => {
          this.setState(
            {
              loading: false,
            },
            () => {
              if (response) {
                this.props.handleConfirm();
              }
            }
          );
        });
      }
    );
  };

  // -------------------------- RENDER --------------------------

  renderDialogHeader = () => {
    return <DialogTitle id="scroll-dialog-title">Update Scheduled For</DialogTitle>;
  };

  renderDialogBody = () => {
    const { scheduledFor } = this.state;
    return (
      <DialogContent dividers>
        <Grid container spacing={1} direction="row" justify="center" alignItems="center">
          <Grid item>
            <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
              <DatePicker
                autoOk
                className="date-picker"
                id="scheduledFor"
                variant="static"
                fullWidth
                value={scheduledFor}
                minDate={moment()}
                label="Scheduled for"
                format="DD/MM/YYYY"
                onChange={(date) => this.handleDateChange(date, "scheduledFor")}
                margin="dense"
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
      </DialogContent>
    );
  };

  renderDialogButton = () => {
    return (
      <DialogActions>
        <Button
          variant="outlined"
          className="add-btn"
          color="default"
          style={{ marginRight: 10 }}
          onClick={() => this.props.handleClose()}
        >
          Cancel
        </Button>
        {this.renderButtonConfirm()}
      </DialogActions>
    );
  };

  renderButtonConfirm = () => {
    const { loading } = this.state;
    if (loading) {
      return (
        <Button
          variant="contained"
          className="add-btn"
          disabled
          color="default"
          style={{ marginRight: 5 }}
          startIcon={<CircularProgress size={15} style={{ color: "#fff" }} />}
        >
          Update
        </Button>
      );
    }
    return (
      <Button
        variant="contained"
        className="add-btn"
        color="primary"
        style={{ marginRight: 5 }}
        onClick={() => this.updateOrder()}
      >
        Update
      </Button>
    );
  };

  // -------------------------- MAIN --------------------------
  render() {
    const { dialogOpen, handleClose } = this.props;
    return (
      <Dialog
        open={dialogOpen}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        {this.renderDialogHeader()}
        {this.renderDialogBody()}
        {this.renderDialogButton()}
      </Dialog>
    );
  }
}

const mapStateToProp = (state) => ({
  user: state.userReducer.user,
});

const mapDispatchToProps = {
  firestoreUpdateOrderSchedule,
};

export default connect(mapStateToProp, mapDispatchToProps)(OrderDetailDialogScheduled);
