import React, { Component } from 'react';
import { 
    IconButton,
} from '@material-ui/core';
import emo1a from '../asset/emoji/emo1a.png';
import emo1b from '../asset/emoji/emo1b.png';
import emo2a from '../asset/emoji/emo2a.png';
import emo2b from '../asset/emoji/emo2b.png';
import emo3a from '../asset/emoji/emo3a.png';
import emo3b from '../asset/emoji/emo3b.png';
import emo4a from '../asset/emoji/emo4a.png';
import emo4b from '../asset/emoji/emo4b.png';
import emo5a from '../asset/emoji/emo5a.png';
import emo5b from '../asset/emoji/emo5b.png';

const REACTIONS = [
  { value: '1', src: emo1a, srcSelect: emo1b },
  { value: '2', src: emo2a, srcSelect: emo2b },
  { value: '3', src: emo3a, srcSelect: emo3b },
  { value: '4', src: emo4a, srcSelect: emo4b },
  { value: '5', src: emo5a, srcSelect: emo5b },
];

class EmojiRating extends Component {
    // -------------------------- STATE --------------------------
    constructor() {
        super();
        this.state = {
            selected: null,
        };
    }

    componentDidMount = () => {
        const imagesPreload = [emo1a, emo1b, emo2a, emo2b, emo3a, emo3b, emo4a, emo4b, emo5a, emo5b];
        imagesPreload.forEach((image) => {
            new Image().src = image
        });
    }

    // -------------------------- FUNCTIONS --------------------------

    selectEmotion = (index) => {
        this.setState({
            selected: index
        }, () => this.props.onSelected(index + 1));
    }

    // -------------------------- FIREBASE FUNCTIONS --------------------------

    // -------------------------- RENDER --------------------------

    renderReactions = () => {
        const { width, space } = this.props;
        const length = REACTIONS.length;
        const totalSpace = ((length - 1) * space) - 40;
        const itemW = Math.round((width - totalSpace) / length);
        return (
            <div style={{ width, height: itemW, flexDirection: 'row', justifyContent: 'space-between', marginHorizontal: 20, display: 'flex' }}>
                { REACTIONS.map((item, index) => this.renderReactionIcons({ item, index, width: itemW })) }
            </div>
        );
    }

    renderReactionIcons = ({ item, index, width }) => {
        const { selected } = this.state;
        const src = selected === index ? item.srcSelect : item.src;
        return (
            <div
                key={index}
                style={{ height: width, width, cursor: 'pointer' }}
                onClick={() => this.selectEmotion(index)}
            >
                <img 
                    src={src}
                    alt='reaction-item'
                    style={{ width: '100%', height: 'auto' }}
                />
            </div>
        );
    }

    // -------------------------- MAIN --------------------------

    render() {
        return (
            <div style={styles.container}>
                { this.renderReactions() }
            </div>
        );
    }
}

export default EmojiRating;

const styles  = {
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'transparent',
    },
    reactions: {
        flex: 1,
        flexDirection: 'row',
    },
    icon: {
        flex: 1,
    },
};
