import React, { Component } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { compose } from "redux";
import { connect } from "react-redux";
import { DropzoneArea } from "material-ui-dropzone";
import Resizer from "react-image-file-resizer";

class OrderFeedbackDialogImageAdd extends Component {
  // ====================== Inits ====================== //

  constructor(props) {
    super(props);
    // state
    this.state = {
      error: "",
      files: [],
    };
  }

  // ====================== Functions ====================== //

  resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        600,
        600,
        "JPEG",
        90,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });

  handleUpload = (images) => {
    let files = [];
    const promises =
      images &&
      images.map((item) => {
        return this.resizeFile(item).then((image) => {
          const base64 = image.replace("data:image/jpeg;base64,", "");
          files.push(base64);
        });
      });
    Promise.all(promises).then(() => {
      this.setState({
        files,
      });
      console.log(files);
    });
  };

  handleUnUpload = () => {
    this.setState({
      files: [],
    });
    this.props.handleClose();
  };

  // ====================== Firebase Functions ====================== //

  // ====================== Render Component ====================== //
  // ====================== Render Main ====================== //

  render() {
    const { files } = this.state;
    const { dialogOpen, handleClose, filesLimit } = this.props;
    const disabled = files.length < 1 ? true : false;
    return (
      <Dialog
        open={dialogOpen}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="scroll-dialog-title">Select Pictures</DialogTitle>
        <DialogContent dividers>
          <DropzoneArea
            onChange={this.handleUpload}
            acceptedFiles={["image/jpeg", "image/png"]}
            filesLimit={filesLimit}
            maxFileSize={6000000}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            type="submit"
            variant="contained"
            className="add-btn"
            style={{ backgroundColor: disabled ? "#999" : "green", color: "#fff" }}
            disabled={disabled}
            onClick={() => this.props.handleConfirm(files)}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapDispatchToProps = {};

export default compose(connect(null, mapDispatchToProps))(OrderFeedbackDialogImageAdd);
