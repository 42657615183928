export const styles = {
    container: {
        flex: 1,
    },
    content: {
        padding: 8,
        paddingBottom: 30,
    },
    button: { 
        borderRadius: 4,
        marginTop: 10,
        backgroundColor: 'green',
        color: '#fff'
    },
    idStatus: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    bold: {
        fontSize: 15,
        fontWeight: 'bold'
    },
    title: {
        fontSize: 15,
        color: '#1976d2',
        fontWeight: 'bold',
        marginTop: 15,
        marginBottom: 5,
    },
    trackingBox: {
        marginVertical: 10
    },
    tracking: {
        fontSize: 13,
        color: '#777',
        marginBottom: 3,
    },
    detailVote: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        marginVertical: 5
    },
    detailTitle: {
        justifyContent: 'center',
        width: 100,
    },
    buttonContainer: {
        justifyContent: 'center',
        flexDirection: 'row',
    },
    cameraButton: {
        padding: 5,
        borderWidth: 1,
        borderColor: '#c8c8c8',
        borderRadius: 5,
        width: 60,
        height: 60,
    },
    galleryContainer: {
        flexDirection: 'row',
        marginVertical: 10,
        maxWidth: 300,
    },
    imagesContainer: {
        marginHorizontal: 5,
        padding: 5,
        borderWidth: 1,
        borderRadius: 3,
        borderColor: '#c8c8c8',
        borderStyle: 'dashed',
        position: 'relative',
        marginBottom: 20,
        marginRight: 10,
    },
    image: {
        height: 'auto',
        width: '100%',
    },
    closeButton: {
        position: 'absolute',
        right: 5,
        top: 5,
        backgroundColor: '#fff',
        padding: 0,
        borderWidth: 1,
        borderRadius: 100,
        borderColor: '#eee',
    },
    input: {
        fontSize: 13,
        backgroundColor: '#fff',
        borderRadius: 5,
        marginBottom: 10,
    },
    noteContainer: {
        position: 'relative',
        marginVertical: 2,
    },
    noteCount: {
        position: 'absolute',
        top: -2,
        right: 5,
        fontSize: 12,
        backgroundColor: '#fff',
    },
};
