/* eslint-disable no-unused-vars */
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import { LOCAL_USER, REF_CONTACT, GET_USER_INFO_SUCCESS } from "../ref";

export const firestoreGetUserInfo = (callback) => (dispatch) => {
  const userJson = localStorage.getItem(LOCAL_USER);
  if (userJson) {
    const userObj = JSON.parse(userJson);
    const contactRef = firebase.firestore().collection(REF_CONTACT);
    const id = userObj.id;
    contactRef
      .doc(id)
      .get()
      .then((snapshot) => {
        if (snapshot.exists) {
          const data = snapshot.data();
          const updateJson = JSON.stringify({
            id: data.id,
          });
          localStorage.setItem(LOCAL_USER, updateJson);
          getUserInfoSuccess(dispatch, data);
          if (callback) {
            callback(data);
          }
        }
      });
  }
};

const getUserInfoSuccess = (dispatch, user) => {
  dispatch({
    type: GET_USER_INFO_SUCCESS,
    payload: user,
  });
};
