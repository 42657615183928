import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import {
  CssBaseline,
  Container,
  Grid,
  ButtonGroup,
  Button,
  IconButton,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import { ArrowBackIos, ThumbUpAltOutlined, ThumbDownAltOutlined } from "@material-ui/icons";
import moment from "moment";
import Navbar from "../00_Nav/nav_bar";
import { styles } from "./styles";
import EmojiDetail from "../../component/emoji";
import { URL } from "../../config/config";

class FeedbackDetail extends Component {
  // -------------------------- STATE --------------------------
  constructor() {
    super();
    this.state = {
      reaction: null,
      noDamage: null,
      noWinkles: null,
      noStained: null,
      images: [],
      max: 5,
      comment: "",
      dialogSuccess: false,
      dialogError: false,
      dialogImage: false,
      inputLength: {
        note: 300,
      },
    };
  }

  // -------------------------- FUNCTIONS --------------------------

  // -------------------------- FIREBASE FUNCTIONS --------------------------

  // -------------------------- RENDER --------------------------

  renderLeftIcon = () => {
    return (
      <IconButton
        color="inherit"
        aria-label="back drawer"
        edge="start"
        onClick={() => this.props.history.goBack()}
      >
        <ArrowBackIos style={{ color: "#fff" }} />
      </IconButton>
    );
  };

  renderOrderId = () => {
    const { order } = this.props;
    const orderId = order && order.orderId;
    const createAt = order && order.createAt;
    const orderDate = createAt && moment(createAt.toDate()).format("DD MMM YYYY hh:mmA");
    const reference = order && order.reference;
    return (
      <Grid container spacing={1} direction="row" justify="space-between" alignItems="center">
        <Grid item xs={12}>
          Order Date: {orderDate}
        </Grid>
        <Grid item xs={12}>
          Order ID: {orderId}
        </Grid>
        <Grid item xs={12}>
          Ref #: {reference}
        </Grid>
      </Grid>
    );
  };

  renderRating = () => {
    const { feedback } = this.props;
    const reaction = feedback && feedback.reaction;
    return (
      <Grid container spacing={1} direction="row" justify="space-between" alignItems="center">
        <Grid item xs={12}>
          <h3>Overall rating</h3>
        </Grid>
        <Grid item xs={12} style={{ marginVertical: 20 }}>
          <EmojiDetail width={360} space={15} selected={reaction} />
        </Grid>
      </Grid>
    );
  };

  renderDetail = () => {
    return (
      <Grid container spacing={1} direction="row" justify="space-between" alignItems="center">
        <Grid item xs={12}>
          {this.renderDetailDamage()}
        </Grid>
        <Grid item xs={12}>
          {this.renderDetailWinkles()}
        </Grid>
        <Grid item xs={12}>
          {this.renderDetailStained()}
        </Grid>
      </Grid>
    );
  };

  renderDetailDamage = () => {
    const { feedback } = this.props;
    const noDamage = feedback && feedback.noDamage;
    return (
      <Grid container spacing={1} direction="row" justify="flex-start" alignItems="center">
        <Grid item xs={2}>
          No damage
        </Grid>
        <Grid item>
          <ButtonGroup aria-label="outlined primary button group" style={{ marginTop: 5 }}>
            <Button className={`vote-button up ${noDamage === true ? "active" : ""}`} disabled>
              <ThumbUpAltOutlined />
            </Button>
            <Button className={`vote-button down ${noDamage === false ? "active" : ""}`} disabled>
              <ThumbDownAltOutlined />
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
    );
  };

  renderDetailWinkles = () => {
    const { feedback } = this.props;
    const noWinkles = feedback && feedback.noWinkles;
    return (
      <Grid container spacing={1} direction="row" justify="flex-start" alignItems="center">
        <Grid item xs={2}>
          No winkles
        </Grid>
        <Grid item>
          <ButtonGroup aria-label="outlined primary button group" style={{ marginTop: 5 }}>
            <Button className={`vote-button up ${noWinkles === true ? "active" : ""}`} disabled>
              <ThumbUpAltOutlined />
            </Button>
            <Button className={`vote-button down ${noWinkles === false ? "active" : ""}`} disabled>
              <ThumbDownAltOutlined />
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
    );
  };

  renderDetailStained = () => {
    const { feedback } = this.props;
    const noStained = feedback && feedback.noStained;
    return (
      <Grid container spacing={1} direction="row" justify="flex-start" alignItems="center">
        <Grid item xs={2}>
          No stained
        </Grid>
        <Grid item>
          <ButtonGroup aria-label="outlined primary button group" style={{ marginTop: 5 }}>
            <Button className={`vote-button up ${noStained === true ? "active" : ""}`} disabled>
              <ThumbUpAltOutlined />
            </Button>
            <Button className={`vote-button down ${noStained === false ? "active" : ""}`} disabled>
              <ThumbDownAltOutlined />
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
    );
  };

  renderImageData = () => {
    const { feedback } = this.props;
    const images = feedback && feedback.images;
    const length = images ? images.length : 0;
    const lengthString = `${length}/${5}`;
    return (
      <Grid container spacing={1} direction="row" justify="space-between" alignItems="center">
        <Grid item xs={12}>
          <h3>Pictures ({lengthString})</h3>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1} direction="row" justify="flex-start" alignItems="center">
            {images && images.map((item, index) => this.renderImageItem({ item, index }))}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  renderImageItem = ({ item, index }) => {
    const uri = `data:image/jpeg;base64,${item}`;
    return (
      <Grid item xs={2} key={index} style={styles.imagesContainer}>
        <img src={uri} style={styles.image} alt="screenshot" />
      </Grid>
    );
  };

  renderComment = () => {
    const { feedback } = this.props;
    const comment = feedback && feedback.comment;
    const length = 300 - comment.length;
    return (
      <Grid
        container
        spacing={1}
        direction="row"
        justify="flex-start"
        alignItems="center"
        style={{ marginTop: 20 }}
      >
        <Grid item xs={12} className="count-string">
          <TextField
            variant="outlined"
            margin="dense"
            fullWidth
            id="comment"
            label="Comment"
            name="comment"
            type="text"
            value={comment}
            disabled
            InputProps={{
              endAdornment: <InputAdornment position="end">{length}</InputAdornment>,
            }}
            multiline
            rows={2}
          />
        </Grid>
      </Grid>
    );
  };
  // -------------------------- MAIN --------------------------

  render() {
    const { user, order, feedback } = this.props;
    // if (!(feedback && order)) return <Redirect to={URL.orders} />;
    const contactName = user && user.contactName;
    return (
      <div className="root-container">
        <CssBaseline />
        <Container maxWidth="sm">
          <Navbar title="Feedback Detail" subtitle={contactName} left={this.renderLeftIcon()} />
          {this.renderOrderId()}
          {this.renderRating()}
          {this.renderDetail()}
          {this.renderImageData()}
          {this.renderComment()}
        </Container>
      </div>
    );
  }
}

const mapStateToProp = (state) => ({
  user: state.userReducer.user,
  order: state.orderReducer.detail,
  feedback: state.firebaseWeb.feedback,
});

// const mapDispatchToProps = {
// };

export default connect(mapStateToProp, null)(FeedbackDetail);
