import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { 
  Drawer, 
  AppBar, 
  Toolbar, 
  List, 
  CssBaseline, 
  Typography, 
  Divider, 
  IconButton, 
  ListItem, 
  ListItemIcon, 
  ListItemText,
  Tooltip,
} from '@material-ui/core';
import { 
  Menu, 
  PowerSettingsNewOutlined,
  RotateLeft,
} from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import logo from '../../config/img/logo.png';
import { firebaseLogout } from '../../store/actions/firebase.action';
import { URL, SANDBOX } from '../../config/config';
import orderSVG from '../../asset/svg/order_black.svg';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

const SignInLink = (props) => {
    const classes = useStyles();
    // const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    let history = useHistory();
    const handleDrawerOpen = () => {
        setOpen(!open);
    };
    return (
      <div className='root-container'>
        <CssBaseline />
          <AppBar
            position='fixed'
            className={clsx(classes.appBar, {
                [classes.appBarShift]: open,
            })}
          >
            <Toolbar>
            
            { props.left ? props.left : <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
            >
                <Menu/>
            </IconButton> }
            <div className='title-cover'>
              <Typography variant="h6" noWrap>
                {props.title ? props.title : ''}{ SANDBOX ? ' - SandBox' : ''} 
              </Typography>
              <span className='subtitle'>
                {props.subtitle ? props.subtitle : ''}
              </span>
            </div>
            <div className='appbar-right'>{props.right ? props.right : null}</div>
            </Toolbar>
          </AppBar>
          <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={open}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div className={classes.toolbar}>
              <img src= { logo } alt="logo" className='nav-logo'/>
            </div>
            <Divider />
            <List>

              <ListItem button component="a" href={URL.orders}>
                <Tooltip title="Orders Management" aria-label="Orders Management" placement="top">
                  <ListItemIcon>
                      <img className='side-menu-icon' alt='Orders Management' src={orderSVG} />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText className='font13'>Orders Management</ListItemText>
              </ListItem>

              <ListItem button component="a" href={URL.suppliedVSReturn}>
                <Tooltip title="Supplied vs Returned" aria-label="Supplied vs Returned" placement="top">
                  <ListItemIcon>
                    <RotateLeft style={{ color: '#333' }} />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText className='font13' >Supplied vs Returned</ListItemText>
              </ListItem>

            </List>
            <Divider />
            <List>
                <ListItem button onClick={props.firebaseLogout}>
                    <ListItemIcon><PowerSettingsNewOutlined /></ListItemIcon>
                    <ListItemText>Logout</ListItemText>
                </ListItem>
            </List>
        </Drawer>
      </div>
    );
}
const mapStateToProps = (state) => ({
    setting: state.firebaseWeb.setting,
});

export default connect(mapStateToProps, {
    firebaseLogout
})(SignInLink);
