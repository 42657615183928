import React, { Component } from "react";
import { connect } from "react-redux";
import {
  CssBaseline,
  Container,
  Grid,
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TablePagination,
} from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import Navbar from "../00_Nav/nav_bar";
import { firestoreGetSuppliedReturnFromTo } from "../../store/actions/firebase.action";
import { firestoreGetUserInfo } from "../../store/actions/user.action";
import { suppliedReturnToData } from "../../util/refine.report.functions";

class ReportSuppliedAndReturnScreen extends Component {
  // -------------------------- STATE --------------------------
  constructor() {
    super();
    this.state = {
      from: moment().subtract(1, "month").startOf("day"),
      to: moment().endOf("day"),
      loading: false,
      data: null,
      orderBy: "item",
      order: "desc",
      header: [
        { id: "item", label: "Item", align: "left", sortable: false },
        { id: "totalSupplied", label: "Total Supplied", align: "center", sortable: false },
        { id: "totalReturned", label: "Total Returned", align: "center", sortable: false },
        { id: "percent", label: "%", align: "center", sortable: false },
      ],
      rowsPerPage: 20,
      page: 0,
    };
  }

  componentDidMount = () => {
    this.updateData();
  };

  // -------------------------- FUNCTIONS --------------------------

  handleChange = ({ id, value }) => {
    this.setState({
      [id]: value,
    });
  };

  handleDateChange = (date, id) => {
    this.setState({
      [id]: date,
    });
  };

  handleChangePage = (event, page) => {
    this.setState({
      page,
    });
  };

  handleChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    this.setState({
      rowsPerPage,
      page: 0,
    });
  };

  // -------------------------- FIREBASE FUNCTIONS --------------------------

  updateData = () => {
    const { from, to } = this.state;
    this.props.firestoreGetUserInfo((user) => {
      if (user) {
        this.setState(
          {
            loading: true,
          },
          () => {
            this.props.firestoreGetSuppliedReturnFromTo({ from, to, contact: user }, () => {
              this.setState(
                {
                  loading: false,
                },
                () => this.refineData()
              );
            });
          }
        );
      }
    });
  };

  refineData = () => {
    const { templates } = this.props;
    const { reportReturns, reportSupplieds } = this.props;
    const data = suppliedReturnToData({ reportReturns, reportSupplieds, templates });
    this.setState({
      data,
    });
  };

  // -------------------------- RENDER --------------------------

  renderFromToPicker = () => {
    const { from, to } = this.state;
    return (
      <Grid container spacing={1} direction="row" justify="flex-start" alignItems="center">
        <Grid item xs={5}>
          <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
            <DatePicker
              autoOk
              className="date-picker"
              variant="inline"
              inputVariant="outlined"
              fullWidth
              value={from}
              maxDate={to}
              label="From"
              format="DD/MM/YYYY"
              onChange={(date) => this.handleDateChange(date, "from")}
              margin="dense"
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={5}>
          <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
            <DatePicker
              autoOk
              className="date-picker"
              variant="inline"
              inputVariant="outlined"
              fullWidth
              minDate={from}
              value={to}
              label="To"
              format="DD/MM/YYYY"
              onChange={(date) => this.handleDateChange(date, "to")}
              margin="dense"
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={2}>
          {this.renderButton()}
        </Grid>
      </Grid>
    );
  };

  renderButton = () => {
    const { from, to, loading } = this.state;
    const disabled = !(from && to && !loading);
    if (loading) {
      return (
        <Button
          variant="contained"
          color="primary"
          disabled
          fullWidth
          startIcon={<CircularProgress size={15} />}
        >
          OK
        </Button>
      );
    }
    return (
      <Button
        variant="contained"
        color="primary"
        disabled={disabled}
        fullWidth
        onClick={() => this.updateData()}
        style={{ fontSize: 13 }}
      >
        OK
      </Button>
    );
  };

  renderTable = () => {
    return (
      <Paper style={{ marginBottom: 20 }}>
        <TableContainer className="report-table">
          <Table stickyHeader aria-label="sticky table">
            {this.renderTableHead()}
            {this.renderTableBody()}
          </Table>
        </TableContainer>
        {this.renderTableFooter()}
      </Paper>
    );
  };

  renderTableHead = () => {
    const { header, data, orderBy, order } = this.state;
    const disabled = !(data && data.length > 0);
    return (
      <TableHead>
        <TableRow>
          {header &&
            header.map((headCell) => (
              <TableCell
                key={headCell.id}
                align={headCell.align}
                padding="default"
                sortDirection={orderBy === headCell.id ? order : false}
              >
                {this.renderTableSortLabel(headCell, disabled)}
              </TableCell>
            ))}
        </TableRow>
      </TableHead>
    );
  };

  renderTableSortLabel = (headCell, disabled) => {
    const { orderBy, order } = this.props;
    if (headCell.sortable) {
      return (
        <TableSortLabel
          disabled={disabled}
          active={orderBy === headCell.id}
          direction={orderBy === headCell.id ? order : "asc"}
        >
          {headCell.label}
        </TableSortLabel>
      );
    }
    return headCell.label;
  };

  renderTableBody = () => {
    const { rowsPerPage, page, data, loading } = this.state;
    const length = data && data.length;
    let tData = data;
    if (length > rowsPerPage) {
      tData = data && data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    }
    if (!loading) {
      return (
        <TableBody>
          {tData && tData.map((item, index) => this.renderTableRow({ item, index }))}
        </TableBody>
      );
    }
    return <TableBody />;
  };

  renderTableRow = ({ item, index }) => {
    const itemName = item && item.itemName;
    const suppliedQuantity = item && item.suppliedQuantity;
    const returnQuantity = item && item.returnQuantity;
    const percent = item && item.percent;
    const percentString = percent > 0 ? percent.toFixed(1) : "0";
    let className = "white";
    if (percent >= 85) {
      className = "green";
    } else if (percent >= 70 && percent < 85) {
      className = "orange";
    } else if (percent < 70 && percent > 0) {
      className = "red";
    } else if (percent === 0) {
      className = "white";
    }
    return (
      <TableRow hover key={index}>
        <TableCell align="left">{itemName}</TableCell>
        <TableCell align="center">{suppliedQuantity}</TableCell>
        <TableCell align="center">{returnQuantity}</TableCell>
        <TableCell align="center">
          <div className={`percent-status ${className}`}> {percentString}%</div>
        </TableCell>
      </TableRow>
    );
  };

  renderTableFooter = () => {
    const { rowsPerPage, page, data } = this.state;
    const length = data && data.length;
    if (length > rowsPerPage) {
      return (
        <TablePagination
          rowsPerPageOptions={[10, 20, 50, 100]}
          component="div"
          count={length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      );
    }
    return null;
  };

  // -------------------------- MAIN --------------------------

  render() {
    const { user } = this.props;
    const contactName = user?.contactName;
    return (
      <div className="root-container">
        <CssBaseline />
        <Container maxWidth="sm">
          <Navbar title="Supplied vs Returned" subtitle={contactName} />
          <main>
            {this.renderFromToPicker()}
            {this.renderTable()}
          </main>
        </Container>
      </div>
    );
  }
}

const mapStateToProp = (state) => ({
  user: state.userReducer.user,
  reportSupplieds: state.firebaseWeb.reportSupplieds,
  reportReturns: state.firebaseWeb.reportReturns,
  templates: state.firebaseWeb.templates,
});

const mapDispatchToProps = {
  firestoreGetSuppliedReturnFromTo,
  firestoreGetUserInfo,
};

export default connect(mapStateToProp, mapDispatchToProps)(ReportSuppliedAndReturnScreen);
