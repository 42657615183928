import {ORDER_LIST_SUCCESS, GET_ORDER_DETAIL_SUCCESS} from '../ref';

// Tạo model
const initModel = {
  orders: null,
  detail: null,
};

// Phương thức thay đổi dữ liệu của model
export default (state = initModel, action) => {
  switch (action.type) {
    case ORDER_LIST_SUCCESS:
      return {
        ...state,
        orders: action.payload,
      };
    case GET_ORDER_DETAIL_SUCCESS:
      return {
        ...state,
        detail: action.payload,
      };
    default:
      return state;
  }
};
