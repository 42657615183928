export const styles = {
    button: { 
        borderRadius: 5,
        marginTop: 20,
        backgroundColor: 'green',
        color: '#fff'
    },
    buttonView: {
        borderRadius: 5,
        marginTop: 20,
        color: 'green'
    }
};
