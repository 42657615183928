import React, { Component} from 'react';
import { 
    Button, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogTitle,
} from '@material-ui/core';
import { connect } from 'react-redux';

class NewOrderDialogSuccess extends Component {
    // -------------------------- STATE --------------------------
    constructor() {
        super();
        this.state = {
        };
    }
    // -------------------------- FUNCTIONS --------------------------

    // -------------------------- FIREBASE FUNCTIONS --------------------------

    // -------------------------- RENDER --------------------------

    renderDialogHeader = () => {
        return <DialogTitle id="scroll-dialog-title">Successfully sent</DialogTitle>
    }


    renderDialogBody = () => {
        return (
            <DialogContent dividers >
                <p>Thank you for your order. We will handle your order ASAP.</p>
            </DialogContent>
        );
    }

    renderDialogButton = () => {
        return (
            <DialogActions>
                <Button 
                  type="submit"
                  variant="contained"
                  className='add-btn'
                  style={{ backgroundColor: 'green', color: '#fff' }}
                  onClick={() => this.props.handleClose()}
                >
                  OK
                </Button>
            </DialogActions>
        );
    }
    
    
    // -------------------------- MAIN --------------------------
    render() {
        const { dialogOpen, handleClose } = this.props;
        return (
            <Dialog
                open={dialogOpen}
                onClose={handleClose}
                scroll='paper'
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                maxWidth='sm'
                fullWidth
            >
                { this.renderDialogHeader() }
                { this.renderDialogBody() }
                { this.renderDialogButton() }
            </Dialog>
        )
    }
}

// const mapStateToProp = (state) => ({
//     user: state.firebase.user,
// });

// const mapDispatchToProps = {
// };

export default connect(null, null)(NewOrderDialogSuccess);  

