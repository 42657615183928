import { combineReducers } from "redux";
import firebaseWeb from "./firebase.reducer";
import { firestoreReducer } from "redux-firestore";
import { firebaseReducer } from "react-redux-firebase";
import orderReducer from "../reducer/order.reducer";
import userReducer from "../reducer/user.reducer";
// import firebase from './firebase.reducer';

const rootReducer = combineReducers({
  firestore: firestoreReducer,
  firebase: firebaseReducer,
  firebaseWeb,
  orderReducer,
  userReducer,
});

export default rootReducer;
