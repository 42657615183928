import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, CircularProgress } from "@material-ui/core";
import { ErrorOutline, Edit } from "@material-ui/icons";
import { COMPLETED } from "../../../config/stringConfig";
import { styles } from "../styles";
import { URL } from "../../../config/config";
import { firestoreViewFeedback } from "../../../store/actions/firebase.action";

class OrderDetailFeedback extends Component {
  // -------------------------- STATE --------------------------
  constructor() {
    super();
    this.state = {};
  }

  // -------------------------- FUNCTIONS --------------------------

  viewFeedBack = () => {
    const { detail } = this.props;
    const id = detail?.feedback;
    this.setState(
      {
        loading: true,
      },
      () => {
        this.props.firestoreViewFeedback({ id }, () => {
          this.setState(
            {
              loading: false,
            },
            () => this.props.history.push(URL.feedbackDetail)
          );
        });
      }
    );
  };

  leaveFeedback = () => {
    const { detail } = this.props;
    const router = `${URL.newFeedbackRoot}/${detail.orderId}`;
    this.props.history.push(router);
  };

  // -------------------------- FIREBASE FUNCTIONS --------------------------

  // -------------------------- RENDER --------------------------

  // -------------------------- MAIN --------------------------

  render() {
    const { detail } = this.props;
    const { loading } = this.state;
    const feedback = detail?.feedback;
    const status = detail?.status;
    if (status !== COMPLETED) {
      return null;
    }
    if (feedback) {
      if (loading) {
        return (
          <Button
            variant="outlined"
            fullWidth
            style={styles.buttonView}
            startIcon={<CircularProgress size={15} style={{ color: "green" }} />}
          >
            View Feedback
          </Button>
        );
      }
      return (
        <Button
          variant="outlined"
          fullWidth
          style={styles.buttonView}
          onClick={() => this.viewFeedBack()}
          startIcon={<ErrorOutline size={15} />}
        >
          View Feedback
        </Button>
      );
    }
    return (
      <Button
        variant="contained"
        fullWidth
        style={styles.button}
        onClick={() => this.leaveFeedback()}
        startIcon={<Edit size={15} />}
      >
        Leave Feedback
      </Button>
    );
  }
}

const mapStateToProp = (state) => ({});

const mapDispatchToProps = {
  firestoreViewFeedback,
};

export default connect(mapStateToProp, mapDispatchToProps)(OrderDetailFeedback);
