import React, { Component } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import {
  firebaseLogout,
  firestoreSnapshotProducts,
  firestoreSnapshotTemplate,
} from "../../store/actions/firebase.action";
import { firestoreGetUserInfo } from "../../store/actions/user.action";
import EmptyScene from "./empty_scene";
import SignInScene from "../01_Login/login";
import OrrdersScene from "../02_Orders/orders";
import NewOrderScene from "../03_New_Order/new_order";
import OrderDetailScene from "../04_Order_Detail/order_detail";
import NewFeedbackScene from "../05_Order_Feedback/order_feedback";
import FeedbackDetailScene from "../06_Feedback_Detail/feedback_detail";
import ReportSuppliedAndReturnScene from "../07_Report_Supplied_Return/report_supplied_return";
import { URL } from "../../config/config";

class Router extends Component {
  componentDidMount = () => {
    // this.props.firestoreGetUserInfo((user) => {
    //   if (user) {
    //     this.props.firestoreSnapshotProducts({ user });
    //     this.props.firestoreSnapshotTemplate({ user });
    //   }
    // });
  };

  renderStacks = () => {
    const { auth } = this.props;
    if (auth?.uid) {
      return (
        <Switch>
          <Route path={URL.orders} component={OrrdersScene} exact />
          <Route path={URL.newOrder} component={NewOrderScene} />
          <Route path={URL.orderDetail} component={OrderDetailScene} />
          <Route path={URL.newFeedback} component={NewFeedbackScene} />
          <Route path={URL.feedbackDetail} component={FeedbackDetailScene} />
          <Route path={URL.suppliedVSReturn} component={ReportSuppliedAndReturnScene} />
          <Route component={EmptyScene} />
        </Switch>
      );
    }
    return (
      <Switch>
        <Route path={URL.signin} component={SignInScene} exact />
        <Route component={EmptyScene} />
      </Switch>
    );
  };

  render() {
    return <BrowserRouter>{this.renderStacks()}</BrowserRouter>;
  }
}

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
});

const mapDispatchToProps = {
  firebaseLogout,
  firestoreGetUserInfo,
  firestoreSnapshotProducts,
  firestoreSnapshotTemplate,
};

export default connect(mapStateToProps, mapDispatchToProps)(Router);
