import React, { Component } from "react";
import { connect } from "react-redux";
import {
  CssBaseline,
  Container,
  Grid,
  Button,
  TextField,
  CircularProgress,
  InputAdornment,
  IconButton,
  ButtonGroup,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TablePagination,
  Tooltip,
} from "@material-ui/core";
import {
  SearchOutlined,
  BallotOutlined,
  Send,
  LocalLaundryServiceOutlined,
  LocalShippingOutlined,
  AssignmentTurnedInOutlined,
  NoteAddOutlined,
  Block,
} from "@material-ui/icons";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import orderBy from "lodash/orderBy";
import moment from "moment";
import { isDesktop } from "react-device-detect";
import Navbar from "../00_Nav/nav_bar";
import { firestoreGetUserInfo } from "../../store/actions/user.action";
import {
  firestoreSnapshotProducts,
  firestoreSnapshotTemplate,
  unSnapProduct,
  unSnapTemplate,
} from "../../store/actions/firebase.action";
import {
  firestoreGetOrderById,
  firestoreSnapshotOrders,
  unSubOrders,
  setDetail,
} from "../../store/actions/order.action";
import {
  ALL,
  SENT,
  PROCESSING,
  IN_TRANSIT,
  COMPLETED,
  DELIVERY,
  DELIVERY_SCHEDULED,
  CANCELLED,
} from "../../config/stringConfig";
import { URL } from "../../config/config";

class OrdersScreen extends Component {
  // -------------------------- STATE --------------------------
  constructor() {
    super();
    this.state = {
      from: moment().subtract(1, "week").startOf("day"),
      to: moment().add(1, "week").endOf("day"),
      today: moment().format("ddd DD/MM"),
      status: ALL,
      search: "",
      loading: false,
      data: null,
      searchLoading: false,
      orderBy: "date",
      order: "desc",
      header: [
        { id: "date", label: "Date", align: "left", sortable: false },
        { id: "orderId", label: "Order ID", align: "left", sortable: false },
        { id: "status", label: "Status", align: "center", sortable: false },
      ],
      rowsPerPage: 20,
      page: 0,
    };
  }

  componentDidMount = () => {
    this.hanldeLoad();
  };

  hanldeLoad = () => {
    const userJson = localStorage.getItem("user");
    if (userJson) {
      this.props.firestoreGetUserInfo(() => {
        this.updateData();
      });
    } else {
      setTimeout(() => {
        this.hanldeLoad();
      }, 100);
    }
  };

  componentWillUnmount = () => {
    this.props.unSubOrders();
    this.props.unSnapProduct();
    this.props.unSnapTemplate();
  };

  updateData = () => {
    const { user } = this.props;
    const { from, to } = this.state;
    this.setState(
      {
        loading: true,
      },
      () => {
        this.props.unSubOrders();
        this.props.unSnapProduct();
        this.props.unSnapTemplate();
        setTimeout(() => {
          this.props.firestoreSnapshotOrders({ from, to, user }, () => {
            this.setState(
              {
                loading: false,
              },
              () => {
                this.refineData();
              }
            );
          });
          this.props.firestoreSnapshotProducts({ user });
          this.props.firestoreSnapshotTemplate({ user });
        }, 50);
      }
    );
  };

  // -------------------------- FUNCTIONS --------------------------

  handleChangePage = (event, page) => {
    this.setState({
      page,
    });
  };

  handleChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    this.setState({
      rowsPerPage,
      page: 0,
    });
  };

  handleChange = ({ id, value }) => {
    this.setState({
      [id]: value,
    });
  };

  handleSearchChange = (e) => {
    this.setState({
      search: e.target.value,
    });
  };

  handleDateChange = (date, id) => {
    this.setState({
      [id]: date,
    });
  };

  handleChangeStatus = ({ value }) => {
    const { status } = this.state;
    if (value === status) {
      return;
    }
    this.setState(
      {
        status: value,
      },
      () => {
        this.refineData();
      }
    );
  };

  handleSearchByOrderId = () => {
    const { search } = this.state;
    const { user } = this.props;
    if (search) {
      this.setState(
        {
          searchLoading: true,
        },
        () => {
          this.props.firestoreGetOrderById({ orderId: search, user }, () => {
            this.setState(
              {
                searchLoading: false,
              },
              () => {
                this.refineData();
              }
            );
          });
        }
      );
    }
  };

  refineData = () => {
    const { status } = this.state;
    const { orders } = this.props;
    let data = orders;
    if (status === DELIVERY) {
      data =
        orders &&
        orders.filter((item) => item.status === DELIVERY_SCHEDULED || item.status === IN_TRANSIT);
    } else if (status !== "All") {
      data = orders && orders.filter((item) => item.status === status);
    }
    data = orderBy(data, ["scheduledFor"], ["desc"]);
    this.setState({
      data,
    });
  };

  openDetail = ({ item }) => {
    if (isDesktop) {
      window.open(`${URL.orderDetailRoot}/${item.orderId}`, "_blank");
    } else {
      const router = `${URL.orderDetailRoot}/${item.orderId}`;
      this.props.history.push(router);
    }
  };

  // -------------------------- FIREBASE FUNCTIONS --------------------------

  // -------------------------- RENDER --------------------------

  renderFromToPicker = () => {
    const { from, to } = this.state;
    return (
      <Grid container spacing={1} direction="row" justify="flex-start" alignItems="center">
        <Grid item xs={5}>
          <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
            <DatePicker
              autoOk
              className="date-picker"
              variant="inline"
              inputVariant="outlined"
              fullWidth
              value={from}
              maxDate={to}
              label="From"
              format="DD/MM/YYYY"
              onChange={(date) => this.handleDateChange(date, "from")}
              margin="dense"
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={5}>
          <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
            <DatePicker
              autoOk
              className="date-picker"
              variant="inline"
              inputVariant="outlined"
              fullWidth
              minDate={from}
              value={to}
              label="To"
              format="DD/MM/YYYY"
              onChange={(date) => this.handleDateChange(date, "to")}
              margin="dense"
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={2}>
          {this.renderButton()}
        </Grid>
      </Grid>
    );
  };

  renderButton = () => {
    const { from, to, loading } = this.state;
    const disabled = !(from && to && !loading);
    if (loading) {
      return (
        <Button
          variant="contained"
          color="primary"
          disabled
          fullWidth
          startIcon={<CircularProgress size={15} />}
        >
          OK
        </Button>
      );
    }
    return (
      <Button
        variant="contained"
        color="primary"
        disabled={disabled}
        fullWidth
        onClick={() => this.updateData()}
        style={{ fontSize: 13 }}
      >
        OK
      </Button>
    );
  };

  renderSearchOrderId = () => {
    const { search } = this.state;
    return (
      <TextField
        margin="dense"
        label="Search By Order ID"
        variant="outlined"
        fullWidth
        value={search || ""}
        onChange={(e) => this.handleSearchChange(e)}
        onKeyPress={(ev) => {
          if (ev.key === "Enter") {
            this.handleSearchByOrderId();
            ev.preventDefault();
          }
        }}
        InputProps={{
          endAdornment: <InputAdornment position="end">{this.renderSearchButton()}</InputAdornment>,
        }}
      />
    );
  };

  renderSearchButton = () => {
    const { searchLoading } = this.state;
    if (searchLoading) {
      return (
        <IconButton aria-label="search order id" disabled>
          <CircularProgress size={15} />
        </IconButton>
      );
    }
    return (
      <IconButton aria-label="search order id" onClick={() => this.handleSearchByOrderId()}>
        <SearchOutlined />
      </IconButton>
    );
  };

  renderFilterBar = () => {
    const { status } = this.state;
    return (
      <Grid container spacing={1} direction="row" justify="space-between" alignItems="center">
        <Grid item>{status}</Grid>
        <Grid item>
          <ButtonGroup aria-label="outlined primary button group" style={{ marginTop: 5 }}>
            <Button
              className={`filter-button ${status === ALL ? "active" : ""}`}
              onClick={() => this.handleChangeStatus({ value: ALL })}
            >
              <BallotOutlined />
            </Button>
            <Button
              className={`filter-button ${status === SENT ? "active" : ""}`}
              onClick={() => this.handleChangeStatus({ value: SENT })}
            >
              <Send />
            </Button>
            <Button
              className={`filter-button ${status === PROCESSING ? "active" : ""}`}
              onClick={() => this.handleChangeStatus({ value: PROCESSING })}
            >
              <LocalLaundryServiceOutlined />
            </Button>
            <Button
              className={`filter-button ${status === DELIVERY ? "active" : ""}`}
              onClick={() => this.handleChangeStatus({ value: DELIVERY })}
            >
              <LocalShippingOutlined />
            </Button>
            <Button
              className={`filter-button ${status === COMPLETED ? "active" : ""}`}
              onClick={() => this.handleChangeStatus({ value: COMPLETED })}
            >
              <AssignmentTurnedInOutlined />
            </Button>
            <Button
              className={`filter-button ${status === CANCELLED ? "active" : ""}`}
              onClick={() => this.handleChangeStatus({ value: CANCELLED })}
            >
              <Block />
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
    );
  };

  renderTable = () => {
    return (
      <Paper style={{ marginBottom: 20 }}>
        <TableContainer className="report-table">
          <Table stickyHeader aria-label="sticky table">
            {this.renderTableHead()}
            {this.renderTableBody()}
          </Table>
        </TableContainer>
        {this.renderTableFooter()}
      </Paper>
    );
  };

  renderTableHead = () => {
    const { header, data, orderBy, order } = this.state;
    const disabled = !(data && data.length > 0);
    return (
      <TableHead>
        <TableRow>
          {header?.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.align}
              padding="default"
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {this.renderTableSortLabel(headCell, disabled)}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  renderTableSortLabel = (headCell, disabled) => {
    const { orderBy, order } = this.props;
    if (headCell.sortable) {
      return (
        <TableSortLabel
          disabled={disabled}
          active={orderBy === headCell.id}
          direction={orderBy === headCell.id ? order : "asc"}
        >
          {headCell.label}
        </TableSortLabel>
      );
    }
    return headCell.label;
  };

  renderTableBody = () => {
    const { rowsPerPage, page, data, loading } = this.state;
    const length = data && data.length;
    let tData = data;
    if (length > rowsPerPage) {
      tData = data && data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    }
    if (!loading) {
      return (
        <TableBody>
          {tData && tData.map((item, index) => this.renderTableRow({ item, index }))}
        </TableBody>
      );
    }
    return <TableBody />;
  };

  renderTableRow = ({ item, index }) => {
    const { today } = this.state;
    const scheduledFor = item && item.scheduledFor;
    const orderId = item && item.orderId;
    const date = scheduledFor && moment(scheduledFor.toDate()).format("ddd DD/MM");
    return (
      <TableRow
        hover
        key={index}
        onClick={() => this.openDetail({ item })}
        className={today === date ? "active" : "inactive"}
      >
        <TableCell align="left">{date}</TableCell>
        <TableCell align="left">{orderId}</TableCell>
        <TableCell align="center">{this.renderCellStatus({ item })}</TableCell>
      </TableRow>
    );
  };

  renderCellStatus = ({ item }) => {
    const status = item && item.status;
    switch (status) {
      case SENT:
        return <Send style={{ color: "red" }} />;
      case PROCESSING:
        return <LocalLaundryServiceOutlined style={{ color: "#1976d2" }} />;
      case DELIVERY_SCHEDULED:
        return <LocalShippingOutlined style={{ color: "orangered" }} />;
      case IN_TRANSIT:
        return <LocalShippingOutlined style={{ color: "green" }} />;
      case COMPLETED:
        return <AssignmentTurnedInOutlined style={{ color: "green" }} />;
      case CANCELLED:
        return <Block style={{ color: "red" }} />;
      default:
        return <BallotOutlined style={{ color: "#333" }} />;
    }
  };
  renderTableFooter = () => {
    const { rowsPerPage, page, data } = this.state;
    const length = data && data.length;
    if (length > rowsPerPage) {
      return (
        <TablePagination
          rowsPerPageOptions={[10, 20, 50, 100]}
          component="div"
          count={length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      );
    }
    return null;
  };

  renderRightIcon = () => {
    return (
      <Tooltip title="New Order">
        <span>
          <IconButton aria-label="addOrder" onClick={() => this.props.history.push(URL.newOrder)}>
            <NoteAddOutlined style={{ color: "#fff" }} />
          </IconButton>
        </span>
      </Tooltip>
    );
  };

  // -------------------------- MAIN --------------------------
  render() {
    const { user } = this.props;
    const contactName = user?.contactName;
    return (
      <div className="root-container">
        <CssBaseline />
        <Container maxWidth="sm">
          <Navbar title="Orders" subtitle={contactName} right={this.renderRightIcon()} />
          <main>
            {this.renderFromToPicker()}
            {this.renderSearchOrderId()}
            {this.renderFilterBar()}
            {this.renderTable()}
          </main>
        </Container>
      </div>
    );
  }
}

const mapStateToProp = (state) => ({
  auth: state.firebase.auth,
  user: state.userReducer.user,
  orders: state.orderReducer.orders,
});

const mapDispatchToProps = {
  firestoreSnapshotOrders,
  unSubOrders,
  firestoreGetOrderById,
  firestoreGetUserInfo,
  setDetail,
  firestoreSnapshotProducts,
  firestoreSnapshotTemplate,
  unSnapProduct,
  unSnapTemplate,
};

export default connect(mapStateToProp, mapDispatchToProps)(OrdersScreen);
