// local
export const LOCAL_USER = "user";
// firebase ref
export const REF_BRANCH = "branch";
export const REF_PRODUCT = "products";
export const REF_CONTACT = "contacts";
export const REF_TEMPLATE = "templates";
export const REF_RETURN_TEMPLATE = "returnTemplates";
export const REF_ORDER = "orders";
export const REF_RETURN = "returns";
// redux ref
export const FIREBASE_AUTH_STATUS = "firebase_auth_status_update";
export const LOGIN_LOADING = "firebase_login_loading";
export const LOGIN_SUCCESS = "firebase_login_success";
export const LOGIN_FAILED = "firebase_login_fail";
export const LOGOUT_SUCCESS = "firebase_logout_success";

export const GET_USER_INFO_SUCCESS = "firebase_get_user_info_success";

export const BRANCH_SUCCESS = "firestore_branch_success";
export const PRODUCT_LIST_SUCCESS = "firestore_products_list_success";
export const CONTACT_LIST_SUCCESS = "firestore_contacts_list_success";
export const TEMPLATE_LIST_SUCCESS = "firestore_template_list_success";
export const RETURN_TEMPLATE_LIST_SUCCESS = "firestore_return_template_list_success";
export const ORDER_LIST_SUCCESS = "firestore_order_list_success";
export const ORDER_SNAPSHOT_SUCCESS = "firestore_order_snapshot_success";
export const GET_ORDER_DETAIL_SUCCESS = "get_order_detail_success";
export const RETURN_LIST_SUCCESS = "firestore_return_list_success";
export const RETURN_SNAPSHOT_SUCCESS = "firestore_return_snapshot_success";
export const REPORT_SUPPLIED_LIST_SUCCESS = "firestore_report_supplied_list_success";
export const REPORT_RETURN_LIST_SUCCESS = "firestore_report_return_list_success";
