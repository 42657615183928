import firebase from "firebase/app";
import "firebase/firestore";
import { LOCAL_USER, REF_ORDER, ORDER_LIST_SUCCESS, GET_ORDER_DETAIL_SUCCESS } from "../ref";

// -------------------------- Order Add --------------------------

// -------------------------- Order Update --------------------------

// -------------------------- Order Get List --------------------------
export const firestoreGetOrderListFromTo =
  ({ from, to, user }, callback) =>
  (dispatch) => {
    const orderRef = firebase.firestore().collection(REF_ORDER);
    const owner = user?.owner;
    const branch = user?.branch;
    const contactId = user?.id;
    const start = from._d;
    const end = to._d;
    orderRef
      .where("owner", "==", owner)
      .where("branch", "==", branch)
      .where("contactId", "==", contactId)
      .where("scheduledFor", ">=", start)
      .where("scheduledFor", "<=", end)
      .get()
      .then((snapshot) => {
        if (snapshot) {
          const data = snapshot.docs.map((doc) => doc.data());
          getOrderListSuccess(dispatch, data);
        }
        if (callback) {
          callback();
        }
      });
  };

const getOrderListSuccess = (dispatch, data) => {
  dispatch({
    type: ORDER_LIST_SUCCESS,
    payload: data,
  });
};

// -------------------------- Order Get Id --------------------------

export const firestoreGetOrderById =
  ({ orderId, user }, callback) =>
  (dispatch) => {
    const ordersRef = firebase.firestore().collection("orders");
    const emailAddress = user.emailAddress;
    const id = orderId && orderId.toUpperCase();
    ordersRef
      .where("emailAddress", "==", emailAddress)
      .where("orderId", "==", id)
      .get()
      .then((snapshot) => {
        if (snapshot) {
          const data = snapshot.docs.map((doc) => doc.data());
          getOrderListSuccess(dispatch, data);
        }
        if (callback) {
          callback();
        }
      });
  };

// -------------------------- Order Snapshot --------------------------
const subOrders = [];
export const firestoreSnapshotOrders =
  ({ from, to, user }, callback) =>
  (dispatch) => {
    const ordersRef = firebase.firestore().collection(REF_ORDER);
    const owner = user?.owner;
    const branch = user?.branch;
    const contactId = user?.id;
    const start = from._d;
    const end = to._d;
    const unSub = ordersRef
      .where("owner", "==", owner)
      .where("branch", "==", branch)
      .where("contactId", "==", contactId)
      .where("scheduledFor", ">=", start)
      .where("scheduledFor", "<=", end)
      .onSnapshot((snapshot) => {
        if (snapshot) {
          if (snapshot) {
            const data = snapshot.docs.map((doc) => doc.data());
            getOrderListSuccess(dispatch, data);
          }
          if (callback) {
            callback();
          }
        }
      });
    subOrders.push(unSub);
  };

export const unSubOrders = () => (dispatch) => {
  subOrders.forEach((subscriber) => {
    subscriber();
  });
  subOrders.length = 0;
};

// -------------------------- Order Detail --------------------------

export const setDetail =
  ({ detail }, callback) =>
  (dispatch) => {
    getOrderDetailSuccess(dispatch, detail);
    if (callback) {
      callback();
    }
  };

// -------------------------- Order Detail --------------------------

const subDetail = [];
export const firestoreSnapshotOrdersDetail =
  ({ id }, callback) =>
  (dispatch) => {
    const orderRef = firebase.firestore().collection(REF_ORDER);
    const unSub = orderRef.doc(id).onSnapshot((snapshot) => {
      if (snapshot) {
        const data = snapshot.data();
        getOrderDetailSuccess(dispatch, data);
        if (callback) {
          callback();
        }
      }
    });
    subDetail.push(unSub);
  };

const getOrderDetailSuccess = (dispatch, data) => {
  dispatch({
    type: GET_ORDER_DETAIL_SUCCESS,
    payload: data,
  });
};

export const unSubOrderDetail = () => () => {
  subDetail.forEach((subscriber) => {
    subscriber();
  });
  subDetail.length = 0;
};

// -------------------------- Order Detail --------------------------
export const firestoreGetOrderDetail =
  ({ orderId }, callback) =>
  (dispatch) => {
    const ordersRef = firebase.firestore().collection("orders");
    const userJson = localStorage.getItem(LOCAL_USER);
    const userObj = JSON.parse(userJson);
    const contactId = userObj.id;
    ordersRef
      .where("contactId", "==", contactId)
      .where("orderId", "==", orderId)
      .get()
      .then((snapshot) => {
        if (snapshot) {
          const data = snapshot.docs.map((doc) => doc.data());
          const detail = data?.length === 1 ? data[0] : null;
          if (callback) {
            callback(detail);
          }
        }
      });
  };
