import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid, Paper } from "@material-ui/core";
import OrderDetailDialogImage from "../dialogs/order_detail_image";

class OrderDetailPicture extends Component {
  // -------------------------- STATE --------------------------
  constructor() {
    super();
    this.state = {
      dialogImage: false,
      photo: null,
    };
  }

  // -------------------------- FUNCTIONS --------------------------

  openDialogImage = (photo) => {
    this.setState({
      dialogImage: true,
      photo,
    });
  };

  closeDialogImage = () => {
    this.setState({
      dialogImage: false,
    });
  };

  // -------------------------- FIREBASE FUNCTIONS --------------------------

  // -------------------------- RENDER --------------------------

  renderSignature = () => {
    const { detail } = this.props;
    const signature = detail?.signature;
    const signatureName = detail?.signatureName;
    return signature ? (
      <Grid item xs={4}>
        <Paper style={{ padding: 5 }}>
          <h4 style={{ margin: 0 }}>Signature:</h4>
          <img
            src={signature}
            alt="signature"
            className="order-detail-picture"
            onClick={() => this.openDialogImage(signature)}
          />
          <span>{signatureName}</span>
        </Paper>
      </Grid>
    ) : null;
  };

  renderDropOff = () => {
    const { detail } = this.props;
    const dropoff = detail?.dropoff;
    return dropoff ? (
      <Grid item xs={4}>
        <Paper style={{ padding: 5 }}>
          <h4 style={{ margin: 0 }}>Droff Off Photo:</h4>
          <img
            src={dropoff}
            alt="signature"
            className="order-detail-picture"
            onClick={() => this.openDialogImage(dropoff)}
          />
        </Paper>
      </Grid>
    ) : null;
  };

  renderPickUp = () => {
    const { detail } = this.props;
    const pickup = detail?.pickup;
    return pickup ? (
      <Grid item xs={4}>
        <Paper style={{ padding: 5 }}>
          <h4 style={{ margin: 0 }}>Pick Up Photo:</h4>
          <img
            src={pickup}
            alt="signature"
            className="order-detail-picture"
            onClick={() => this.openDialogImage(pickup)}
          />
        </Paper>
      </Grid>
    ) : null;
  };

  // -------------------------- MAIN --------------------------

  render() {
    const { dialogImage, photo } = this.state;
    return (
      <Grid container spacing={1} direction="row" justify="flex-start" style={{ marginTop: 10 }}>
        {this.renderSignature()}
        {this.renderDropOff()}
        {this.renderPickUp()}
        <OrderDetailDialogImage
          dialogOpen={dialogImage}
          photo={photo}
          handleClose={this.closeDialogImage}
        />
      </Grid>
    );
  }
}

const mapStateToProp = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProp, mapDispatchToProps)(OrderDetailPicture);
