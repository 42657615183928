import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid, TextField } from "@material-ui/core";
import { CANCELLED } from "../../../config/stringConfig";

class OrderDetailNote extends Component {
  // -------------------------- STATE --------------------------
  constructor() {
    super();
    this.state = {};
  }

  // -------------------------- FUNCTIONS --------------------------

  // -------------------------- FIREBASE FUNCTIONS --------------------------

  // -------------------------- RENDER --------------------------

  // -------------------------- MAIN --------------------------

  render() {
    const { detail } = this.props;
    let note = detail?.note || "";
    const status = detail?.status;
    if (status === CANCELLED) {
      note = detail?.cancelReason;
    }
    return (
      <Grid
        container
        spacing={1}
        direction="row"
        justify="flex-start"
        alignItems="center"
        style={{ marginTop: 10 }}
      >
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            margin="dense"
            fullWidth
            id="note"
            label="Notes"
            name="note"
            type="text"
            value={note}
            multiline
            disabled
          />
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProp = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProp, mapDispatchToProps)(OrderDetailNote);
