import React, { Component } from "react";
import { connect } from "react-redux";
import {
  CssBaseline,
  Container,
  Grid,
  TextField,
  CircularProgress,
  InputAdornment,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { Send, ArrowBackIos, AddCircleOutline, RemoveCircleOutline } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { sum } from "lodash";
import { styles } from "./styles";
import {
  firestoreAddOrder,
  setLocalTemplate,
  getLocalTemplate,
  firestoreGetNewOrderId,
} from "../../store/actions/firebase.action";
import Navbar from "../00_Nav/nav_bar";
import NewOrderDialogSuccess from "./dialogs/new_order_dialog_success";
import NewOrderDialogError from "./dialogs/new_order_dialog_error";

class NewOrderScreen extends Component {
  // -------------------------- STATE --------------------------
  constructor() {
    super();
    this.state = {
      scheduledFor: moment(),
      note: "",
      reference: "",
      loading: false,
      error: "",
      orderList: null,
      newProduct: null,
      quantity: "1",
      autocomplete: null,
      dialogSuccess: false,
      dialogError: false,
      orderId: null,
      loadingId: false,
      inputLength: {
        reference: 20,
        note: 300,
      },
      quantityRegex: /^[0-9]{0,3}$/,
    };
  }

  componentDidMount = () => {
    this.getTemplateData(); // Lấy template
    this.getNewOrderId(); // Lấy order id mới
  };

  getTemplateData = () => {
    const { templates } = this.props;
    this.props.getLocalTemplate({ templates }, (orderList) => {
      this.setState({
        orderList,
      });
    });
  };

  getNewOrderId = () => {
    this.setState(
      {
        loadingId: true,
      },
      () => {
        this.props.firestoreGetNewOrderId((response) => {
          if (response) {
            this.setState({
              orderId: response,
              loadingId: false,
            });
          } else {
            this.getNewOrderId();
          }
        });
      }
    );
  };

  // -------------------------- FUNCTIONS --------------------------

  handleChange = (e) => {
    const { inputLength } = this.state;
    const id = [e.target.id];
    const value = e.target.value;
    const condition = inputLength && inputLength[id];
    if (!condition || value.length < condition) {
      this.setState({
        [id]: value,
      });
    }
  };

  handleDateChange = (date, id) => {
    this.setState({
      [id]: date,
    });
  };

  handleChangeQuantity = (e, index) => {
    const { orderList, quantityRegex } = this.state;
    const value = e.target.value;
    const condition = quantityRegex.test(value);
    if (condition) {
      const newList = orderList;
      newList[index].quantity = value;
      this.setState({
        orderList: newList,
      });
    }
  };

  handleBlurQuantity = (e, index) => {
    const { orderList, quantityRegex } = this.state;
    const value = e.target.value;
    const condition = quantityRegex.test(value);
    if (condition && value === "") {
      const newList = orderList;
      newList[index].quantity = 1;
      this.setState({
        orderList: newList,
      });
    }
  };

  handleChangeNewQuantity = (e) => {
    const { quantityRegex } = this.state;
    const value = e.target.value;
    const condition = quantityRegex.test(value);
    if (condition) {
      this.setState({
        quantity: value,
      });
    }
  };

  handleBlurNewQuantity = (e) => {
    const { quantityRegex } = this.state;
    const value = e.target.value;
    const condition = quantityRegex.test(value);
    if (condition && value === "") {
      this.setState({
        quantity: 1,
      });
    }
  };

  selectProduct = (item) => {
    this.setState({
      newProduct: item,
      autocomplete: null,
    });
  };

  addProductToOrder = () => {
    const { newProduct, quantity, orderList } = this.state;
    const { user, templates } = this.props;
    const owner = user.owner;
    const branch = user.branch;
    const contactName = user.contactName;
    const inventoryItemCode = newProduct.itemCode;
    const description = newProduct.itemName;
    const existTemplate =
      templates && templates.find((item) => item.inventoryItemCode === inventoryItemCode);
    const templateUnitAmount = existTemplate && existTemplate.unitAmount;
    const unitAmount = templateUnitAmount || newProduct.salesUnitPrice; // Giá theo template
    const newItem = {
      owner,
      branch,
      contactName,
      inventoryItemCode,
      description,
      quantity,
      unitAmount,
    };
    const newList = [...orderList, newItem];
    this.setState({
      orderList: newList,
      newProduct: null,
      quantity: "1",
      autocomplete: null,
    });
  };

  removeProductFromOrder = (item) => {
    const { orderList } = this.state;
    const newList =
      orderList && orderList.filter((o) => o.inventoryItemCode !== item.inventoryItemCode);
    this.setState({
      orderList: newList,
      newProduct: null,
      quantity: "1",
      autocomplete: null,
    });
  };

  // Dialog success
  openDialogSuccess = () => {
    this.setState({
      dialogSuccess: true,
    });
  };

  closeDialogSuccess = () => {
    this.setState(
      {
        dialogSuccess: false,
      },
      () => {
        this.props.history.goBack();
      }
    );
  };

  // Dialog error
  openDialogError = () => {
    this.setState({
      dialogError: true,
    });
  };

  closeDialogError = () => {
    this.setState({
      dialogError: false,
    });
  };

  closeDialogErrorAndCall = () => {
    this.setState(
      {
        dialogError: false,
      },
      () => {
        // Linking.openURL(`tel:${SUPPORT_NUMBER}`);
      }
    );
  };
  // -------------------------- FIREBASE FUNCTIONS --------------------------

  addNewOrder = () => {
    const { orderList, note, reference, orderId, scheduledFor } = this.state;
    const { user } = this.props;
    const emailAddress = user.emailAddress;
    const contactName = user.contactName;
    const contactId = user.id;
    const owner = user.owner;
    const branch = user.branch;
    const priceArr =
      orderList &&
      orderList.map((item) => {
        const unitPrice = item.unitAmount ? parseFloat(item.unitAmount) : 0;
        const quantity = item.quantity ? parseFloat(item.quantity) : 0;
        const price = unitPrice * quantity;
        return price;
      });
    const sumPrice = sum(priceArr);
    const subTotal = parseFloat(sumPrice.toFixed(2));
    const total = parseFloat(((sumPrice * 115) / 100).toFixed(2));
    const tax = parseFloat((total - subTotal).toFixed(2));
    const scheduledDate = moment(scheduledFor)._d;
    const data = {
      owner,
      branch,
      contactId,
      emailAddress,
      contactName,
      orderId,
      scheduledFor: scheduledDate,
      scheduleBy: contactName,
      createBy: contactName,
      products: orderList,
      note,
      reference,
      subTotal,
      total,
      tax,
    };
    this.setState(
      {
        loading: true,
      },
      () => {
        this.props.firestoreAddOrder({ data }, (response) => {
          if (response) {
            this.props.setLocalTemplate({ orderList });
            this.setState(
              {
                loading: false,
              },
              () => {
                this.openDialogSuccess();
              }
            );
          } else {
            this.setState(
              {
                loading: false,
              },
              () => {
                this.openDialogError();
              }
            );
          }
        });
      }
    );
  };

  // -------------------------- RENDER --------------------------

  renderLeftIcon = () => {
    return (
      <IconButton
        color="inherit"
        aria-label="back drawer"
        edge="start"
        onClick={() => this.props.history.goBack()}
      >
        <ArrowBackIos style={{ color: "#fff" }} />
      </IconButton>
    );
  };

  renderRightIcon = () => {
    const { loading, orderId, orderList } = this.state;
    const length = (orderList && orderList.length) || 0;
    const disabled = !(orderId && length > 0);
    if (loading) {
      return <CircularProgress size={15} style={{ color: "#fff" }} />;
    }
    return (
      <Tooltip title="Send Order">
        <span>
          <IconButton aria-label="sent" disabled={disabled} onClick={() => this.addNewOrder()}>
            <Send style={{ color: disabled ? "red" : "#fff" }} />
          </IconButton>
        </span>
      </Tooltip>
    );
  };

  renderScheduleAndOrderId = () => {
    const { scheduledFor } = this.state;
    return (
      <Grid container spacing={1} direction="row" justify="flex-start" alignItems="center">
        <Grid item xs={8}>
          {this.renderOrderId()}
        </Grid>
        <Grid item xs={4}>
          <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
            <DatePicker
              autoOk
              className="date-picker"
              id="scheduledFor"
              variant="inline"
              inputVariant="outlined"
              fullWidth
              value={scheduledFor}
              minDate={moment()}
              label="Scheduled for"
              format="DD/MM/YYYY"
              onChange={(date) => this.handleDateChange(date, "scheduledFor")}
              margin="dense"
            />
          </MuiPickersUtilsProvider>
        </Grid>
      </Grid>
    );
  };

  renderOrderId = () => {
    const { orderId, loadingId } = this.state;
    const orderDate = moment().format("DD MMM YYYY");
    if (loadingId) {
      return (
        <Grid container spacing={1} direction="row" justify="flex-start" alignItems="center">
          <Grid item xs={12}>
            Order Date: {orderDate}
          </Grid>
          <Grid item>Order ID:</Grid>
          <Grid item>
            <CircularProgress size={15} />
          </Grid>
        </Grid>
      );
    }
    return (
      <Grid container spacing={1} direction="row" justify="flex-start" alignItems="center">
        <Grid item xs={12}>
          Order Date: {orderDate}
        </Grid>
        <Grid item>Order ID:</Grid>
        <Grid item>{orderId}</Grid>
      </Grid>
    );
  };

  renderReference = () => {
    const { reference } = this.state;
    const length = 20 - reference.length;
    return (
      <Grid
        container
        spacing={1}
        direction="row"
        justify="flex-start"
        alignItems="center"
        style={{ marginTop: 10 }}
      >
        <Grid item xs={12} className="count-string">
          <TextField
            variant="outlined"
            margin="dense"
            fullWidth
            id="reference"
            label="Reference #"
            name="reference"
            type="text"
            value={reference}
            onChange={this.handleChange}
            InputProps={{
              endAdornment: <InputAdornment position="end">{length}</InputAdornment>,
            }}
          />
        </Grid>
      </Grid>
    );
  };

  renderNote = () => {
    const { note } = this.state;
    const length = 300 - note.length;
    return (
      <Grid
        container
        spacing={1}
        direction="row"
        justify="flex-start"
        alignItems="center"
        style={{ marginTop: 10 }}
      >
        <Grid item xs={12} className="count-string">
          <TextField
            variant="outlined"
            margin="dense"
            fullWidth
            id="note"
            label="Notes"
            name="note"
            type="text"
            value={note}
            onChange={this.handleChange}
            InputProps={{
              endAdornment: <InputAdornment position="end">{length}</InputAdornment>,
            }}
            multiline
            rows={2}
          />
        </Grid>
      </Grid>
    );
  };

  renderOrder = () => {
    const { orderList } = this.state;
    return orderList && orderList.map((item, index) => this.renderOrderItem({ item, index }));
  };

  renderOrderItem = ({ item, index }) => {
    const description = item && item.description;
    const inventoryItemCode = item && item.inventoryItemCode;
    const itemFullName = `${inventoryItemCode} - ${description}`;
    const quantity = item.quantity;
    return (
      <Grid
        container
        spacing={1}
        direction="row"
        justify="flex-start"
        alignItems="center"
        style={{ marginTop: 10 }}
        key={index}
      >
        <Grid item sm={9} xs={8}>
          <TextField
            variant="outlined"
            margin="dense"
            fullWidth
            label="Name"
            name="name"
            type="text"
            value={itemFullName}
            disabled
          />
        </Grid>
        <Grid item sm={2} xs={2}>
          <TextField
            className="quantity-input"
            variant="outlined"
            margin="dense"
            fullWidth
            label="Quantity"
            name="quantity"
            type="text"
            value={quantity}
            onChange={(e) => this.handleChangeQuantity(e, index)}
            maxLength="3"
            onBlur={(e) => this.handleBlurQuantity(e, index)}
          />
        </Grid>
        <Grid item sm={1} xs={2}>
          <IconButton
            color="primary"
            aria-label="delete-item"
            component="span"
            onClick={() => this.removeProductFromOrder(item)}
          >
            <RemoveCircleOutline style={styles.orderDelete} />
          </IconButton>
        </Grid>
      </Grid>
    );
  };

  renderAddProduct = () => {
    return (
      <Grid
        container
        spacing={1}
        direction="row"
        justify="flex-start"
        alignItems="center"
        style={{ marginTop: 10 }}
      >
        <Grid item sm={9} xs={8}>
          {this.renderAutoComplete()}
        </Grid>
        <Grid item sm={2} xs={2}>
          {this.renderNewProductQuantity()}
        </Grid>
        <Grid item sm={1} xs={2}>
          {this.renderCheckButton()}
        </Grid>
      </Grid>
    );
  };

  renderAutoComplete = () => {
    const { products } = this.props;
    const { orderList, newProduct } = this.state;
    const disabled = !products;
    return (
      <Autocomplete
        onChange={(e, value) => this.selectProduct(value)}
        value={newProduct}
        getOptionSelected={(option) => option}
        getOptionDisabled={(option) => {
          const itemCode = option.itemCode;
          const exist = orderList && orderList.find((t) => t.inventoryItemCode === itemCode);
          return exist ? true : false;
        }}
        getOptionLabel={(option) => {
          const itemCode = option.itemCode;
          const itemName = option.itemName;
          return `${itemCode} - ${itemName}`;
        }}
        options={products || []}
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            margin="dense"
            label="Search item by ID / Name"
            variant="outlined"
          />
        )}
      />
    );
  };

  renderNewProductQuantity = () => {
    const { quantity } = this.state;
    return (
      <TextField
        className="quantity-input"
        variant="outlined"
        margin="dense"
        fullWidth
        label="Quantity"
        name="quantity"
        type="text"
        value={quantity}
        onChange={this.handleChangeNewQuantity}
        maxLength="3"
        onBlur={this.handleBlurNewQuantity}
      />
    );
  };

  renderCheckButton = () => {
    const { newProduct, quantity } = this.state;
    const disabled = !(newProduct && quantity);
    return (
      <IconButton
        color="primary"
        aria-label="add-item"
        component="span"
        disabled={disabled}
        onClick={() => this.addProductToOrder()}
      >
        <AddCircleOutline style={styles.addNewOrder} />
      </IconButton>
    );
  };

  // -------------------------- MAIN --------------------------
  render() {
    const { dialogSuccess, dialogError } = this.state;
    const { user } = this.props;
    const contactName = user?.contactName;
    return (
      <div className="root-container">
        <CssBaseline />
        <Container maxWidth="sm">
          <Navbar
            title="New Order"
            subtitle={contactName}
            left={this.renderLeftIcon()}
            right={this.renderRightIcon()}
          />
          {this.renderScheduleAndOrderId()}
          {this.renderReference()}
          {this.renderNote()}
          {this.renderOrder()}
          {this.renderAddProduct()}
          <NewOrderDialogSuccess dialogOpen={dialogSuccess} handleClose={this.closeDialogSuccess} />
          <NewOrderDialogError
            dialogOpen={dialogError}
            handleClose={this.closeDialogError}
            handleCloseAndCall={this.closeDialogErrorAndCall}
          />
        </Container>
      </div>
    );
  }
}

const mapStateToProp = (state) => ({
  auth: state.firebase.auth,
  user: state.userReducer.user,
  products: state.firebaseWeb.products,
  templates: state.firebaseWeb.templates,
});

const mapDispatchToProps = {
  firestoreAddOrder,
  setLocalTemplate,
  getLocalTemplate,
  firestoreGetNewOrderId,
};

export default connect(mapStateToProp, mapDispatchToProps)(NewOrderScreen);
