export const VERSION = '1.0.1';
export const BRAND_NAME = 'Chatters Laundry';
export const APP_NAME = 'Chatters Laundry Client';
export const ALL = 'All';
export const SENT = 'Sent';
export const PROCESSING = 'Processing';
export const DELIVERY = 'Delivery';
export const DELIVERY_SCHEDULED = 'Delivery Scheduled';
export const IN_TRANSIT = 'In Transit';
export const COMPLETED = 'Completed';
export const CANCELLED = 'Cancelled';
export const PRINTER = 'Printer';
export const LOCAL_USER = 'user';
export const LOCAL_TEMPLATE = 'Local_Template';
export const SYSTEM_EMAIL = 'system_account_do_not_delete@a1coders.it';
export const SYSTEM_PASSWORD = 'systemadmin123';
export const SUPPORT_NUMBER = '021380607';
export const SUPPORT_NAME = 'John';
export const SNAPSHOT_ADD = 'added';
export const SNAPSHOT_UPDATE = 'modified';
export const SNAPSHOT_REMOVE = 'removed';
